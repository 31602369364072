import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const CancelGameModal = ({ show, onHide, handleConfirmCancel }) => {
  return (
    <>
      <Modal show={show} onHide={onHide} centered backdrop="static">
        <Modal.Header>
          <Modal.Title>Confirm Cancel ?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to cancel this Game?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onHide} style={{borderRadius: "10px"}}>
            Close
          </Button>
          <Button variant="danger" onClick={handleConfirmCancel} style={{borderRadius: "10px"}}>
            Confirm Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CancelGameModal;
