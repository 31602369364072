
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeleteUserTypeModal = (props) => {
    const { show, handleClose, handleDeleteConfirmed, _id, userType } = props
    return (
        <Modal {...props} show={show} onHide={handleClose} centered backdrop="static">
            <Modal.Header >
                <Modal.Title>Delete User Profile</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                 Are you sure you want to delete the <strong>{userType}</strong> ?
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={handleClose} style={{borderRadius: "10px"}}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={() => handleDeleteConfirmed(_id)} style={{borderRadius: "10px"}}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal >
    );
};

export default DeleteUserTypeModal;
