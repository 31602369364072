import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeletePitchNameModal = ({ show, handleClose, handleConfirmDelete, _id, pitchName }) => {
    return (
        <Modal show={show} onHide={handleClose} centered backdrop="static">
            <Modal.Header >
                <Modal.Title>Confirm Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete the name of <strong>{pitchName}</strong> ?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose} style={{borderRadius: "10px"}}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={() => handleConfirmDelete(_id)} style={{borderRadius: "10px"}}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeletePitchNameModal;
