import React from "react";
import Select from "react-select";

const TimeSlotDropdown = ({ handleHours }) => {
  // Generate time slots for 1 to 9 hours
  const generateTimeSlots = () => {
    const slots = [];
    for (let i = 1; i <= 9; i++) {
      const label = `${i} hour${i > 1 ? "s" : ""}`;
      slots.push({
        value: i,
        label: label,
      });
    }
    return slots;
  };

  const timeSlots = generateTimeSlots();

  // Handle selection change
  const handleChange = (selectedOptions) => {
    handleHours(selectedOptions?.value);
  };

  return (
    <Select
      options={timeSlots}
      // isMulti
      onChange={handleChange}
      defaultValue={timeSlots[0]}
      placeholder="Select hours..."
    />
  );
};

export default TimeSlotDropdown;
