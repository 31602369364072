import serverCall from "../serverCall";

//getPitchName
const getPitchName = async () => {
    try {
        const response = await serverCall.get(`/pitch/admin/getall`);
        console.log("getPitchName response", response);
        return response;
    } catch (error) {
        console.log("getPitchName error", error);
    }
};

//CreatePitchName
const CreatePitchName = async (name) => {
    console.log("create city data", name);
    try {
        const response = await serverCall.post(`/pitch/admin/create`,{ name });
        console.log("Create CityDataApi response", response);
        return response;
    } catch (error) {
        console.log("getCreateCityDataApi error", error);
    }
};

//GetPitchNameSingleId
const GetPitchNameSingleId = async (id) => {
  try {
      const response = await serverCall.get(`/pitch/admin/get/${id}`);
      console.log("PitchName SingleId response", response);
      return response;
  } catch (error) {
      console.log("PitchNameSingleId Api error", error);
  }
};

//DeletePitchNameRequest
const DeletePitchNameRequest = async (id) => {
  try {
    const response = await serverCall.delete(`/pitch/admin/delete/${id}`);
    console.log("DeletePitchType response", response);
    return response;
} catch (error) {
    console.log("DeletePitchType Api error", error);
}
};

//UpdatePitchNameRequest
const UpdatePitchNameRequest = async (id, name) => {
  try {
      const response = await serverCall.put(`/pitch/admin/update/${id}`, {name} );
      console.log("UpdateCityData response", response);
      return response;
  } catch (error) {
      console.log("UpdateCityData Api error", error);
  }
};




const PitchNameService = {
    getPitchName,
    CreatePitchName,
    GetPitchNameSingleId,
    DeletePitchNameRequest,
    UpdatePitchNameRequest
};

export default PitchNameService;
