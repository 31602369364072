import React, { useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import UserTypeEditModal from "./UserTypeEditModal";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteUserTypeModal from "./DeleteUserTypeModal";
import UserListData from "../../../../../services/userList.service";
import { useFormik } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import { enqueueSnackbar } from "notistack";
import Avatar from "react-avatar";

const UserType = () => {
  const pageNumner = localStorage.getItem("page");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [showPitchNameModal, setShowPitchNameModal] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [scroll, setScroll] = useState(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(
    pageNumner !== null ? pageNumner : 1
  );
  const [totalCount, setTotalCount] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (scroll) {
      window.scrollTo(0, 0);
    }
  }, [scroll]);

  const getMainUserListData = async () => {
    try {
      setLoading(true);
      const newPageNumber = localStorage.getItem("page");
      if (newPageNumber === null) {
        localStorage.setItem("page", 1);
      }
      const response = await UserListData.getUserListData(
        search,
        pageNumber,
        dataPerPage
      );
      console.log("getMainUserListData_Page", response);
      setData(response.data["data"]);
      setTotalCount(response.data.totalCount);
      console.log("count", totalCount);
      setLoading(false);
      setLoadingData(false);
      enqueueSnackbar("Get User successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (error) {
      console.log("getMainCategory errrrrr", error);
      setLoading(false);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    getMainUserListData();
    localStorage.setItem("page", pageNumber);
  }, [search, pageNumber, dataPerPage]);

  const handleClosePitchNameModal = () => {
    setShowPitchNameModal(false);
    setSelectedUserData(null);
  };

  const handlePerPageChange = async (newPerPage, newPageNumber) => {
    console.log("object");
    setLoading(true);
    setDataPerPage(newPerPage);
    setPageNumber(newPageNumber);
    await getMainUserListData();
    setLoading(false);
  };

  const handlePageChange = async (newPageNumber) => {
    console.log("object");
    setLoading(true);
    setPageNumber(newPageNumber);
    await getMainUserListData();
    setLoading(false);
  };

  const handleEdit = async (id) => {
    setSelectedUserData(id);
    setShowPitchNameModal(true);
    if (id) {
      const response = await UserListData.GetUserListSingleId(id);
      const result = response.data.data;
      console.log("sdsadad", result);
      formik.setFieldValue("email", result.email);
      formik.setFieldValue("_id", result._id);
      formik.setFieldValue("password", result.password);
      formik.setFieldValue("roles", result.roles);
      formik.setFieldValue("firstName", result.firstName);
      formik.setFieldValue("lastName", result.lastName);
    } else {
      formik.setFieldValue("email", "");
      formik.setFieldValue("password", "");
      formik.setFieldValue("roles", "");
      formik.setFieldValue("firstName", "");
      formik.setFieldValue("lastName", "");
      formik.resetForm();
    }
  };

  const handleDelete = (_id, firstName, lastName) => {
    setUserToDelete(_id);
    setShowDeleteModal(true);
    setSelectedUserData({ id: _id, firstName: firstName, lastName: lastName });
  };

  const handleConfirmDelete = async (id) => {
    try {
      const response = await UserListData.DeleteUserListRequest(id);
      console.log("Confirm Delete", response);
      setShowDeleteModal(false);
      getMainUserListData();

      enqueueSnackbar(response.data.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (e) {
      console.log(e.response.data.message);
      enqueueSnackbar("Error deleting city", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  console.log("dataPerPage", dataPerPage);
  const handleFormSubmit = async (values, action) => {
    console.log("handleFormSubmit", values);
    if (!values._id) {
      try {
        const response = await UserListData.CreateUserList(values);
        action.resetForm();

        setShowPitchNameModal(false);
        enqueueSnackbar(response.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        action.resetForm();
        console.log("response", response);
      } catch (e) {
        console.log("e", e);
        enqueueSnackbar(e.response.data.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } else {
      try {
        const response = await UserListData.UpdateUserListRequest(
          values._id,
          values
        );
        console.log("response", response);
        action.resetForm();
        setShowPitchNameModal(false);
        enqueueSnackbar(response.data.message, {
          variant: "success",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        console.log("Update", response.data);
      } catch (e) {
        enqueueSnackbar(e.response.data.message, {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        console.log("e", e);
      }
    }
    getMainUserListData();
  };

  const handleView = (
    id,
    email,
    roles,
    createdAt,
    firstName,
    lastName,
    city,
    emailverified,
    wallet_balance
  ) => {
    navigate(`/userlist/userview/${id}`, {
      state: {
        SubId: id,
        Email: email,
        Usertype: roles,
        UserCreated: createdAt,
        FirstName: firstName,
        LastName: lastName,
        City: city,
        EmailVerify: emailverified,
        WalletBal: wallet_balance,
      },
    });
    console.log("WalletBal", wallet_balance);
    localStorage.setItem("page", pageNumber);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      roles: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required")
        .test(
          "email-validation",
          'Email must contain "@" and end with ".com"',
          (value) => {
            return value && value.includes("@");
          }
        ),
      password: Yup.mixed().required("Password is required"),
      roles: Yup.mixed().required("User type name is required"),
    }),
    onSubmit: handleFormSubmit,
  });

  const columns = [
    {
      name: "Profile Image",
      cell: (row) => <Avatar size="40" src={`${row.profileImage}`} round />,
      sortable: true,
      width: "90px",
    },
    {
      name: "Profile ID",
      selector: (row) => row._id,
      sortable: true,
    },
    {
      name: "User Type",
      selector: (row) => row.roles,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => `${row.firstName} ${row.lastName}`,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: false,
    },
    {
      name: "Created Date",
      selector: (row) => {
        const date = new Date(row.createdAt);
        return date.toLocaleDateString("en-GB");
      },
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Button size="sm" variant="info" onClick={() => handleEdit(row._id)}>
            <i className="fe fe-edit"></i>
          </Button>
          <Button
            size="sm"
            variant="danger"
            onClick={() => handleDelete(row._id, row?.firstName, row?.lastName)}
          >
            <i className="fa fa-trash"></i>
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={() =>
              handleView(
                row?._id,
                row?.email,
                row?.roles,
                row?.createdAt,
                row?.firstName,
                row?.lastName,
                row?.city,
                row?.emailverified,
                row?.wallet_balance
              )
            }
          >
            <i className="fa fa-eye"></i>
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5"> </h2>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">
            <Button
              size="md"
              style={{ borderRadius: "5px" }}
              variant="primary"
              type="button"
              className="my-2 btn-icon-text"
              onClick={() => setShowPitchNameModal(true)} // Open modal on click
            >
              <i className="fe fe-plus me-2"></i>Create New User
            </Button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 mb-4">
          <Card>
            <Card.Body>
              <DataTable
                data={data}
                columns={columns}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleView={handleView}
                progressPending={loading}
                persistTableHead
                pagination
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
                highlightOnHover
                center={true}
                paginationServer
                paginationTotalRows={totalCount}
                paginationRowsPerPageOptions={[10, 50, 100]}
                paginationPerPage={dataPerPage}
                paginationDefaultPage={pageNumber}
                onChangeRowsPerPage={(page) => {
                  handlePerPageChange(page, 1);
                }}
                onChangePage={handlePageChange}
                subHeader
                subHeaderComponent={
                  <div className="d-flex mb-3" style={{ float: "right" }}>
                    <input
                      type="text"
                      placeholder="Search User..."
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      className="form-control"
                      style={{ marginRight: "10px", width: "100%" }}
                    />
                    <Button
                      size="sm"
                      variant="primary"
                      onClick={() => setSearch("")}
                      style={{ borderRadius: "5px" }}
                    >
                      Clear
                    </Button>
                  </div>
                }
              />
            </Card.Body>
          </Card>
        </div>
      </div>
      <UserTypeEditModal
        show={showPitchNameModal}
        handleClose={handleClosePitchNameModal}
        handleSave={formik.handleSubmit}
        isEdit={selectedUserData !== null}
        facilitiseSelect={selectedUserData}
        formik={formik}
        formikErrors={formik.errors}
      />
      <DeleteUserTypeModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        handleDeleteConfirmed={handleConfirmDelete}
        _id={userToDelete}
        userType={
          selectedUserData
            ? `${selectedUserData.firstName} ${selectedUserData.lastName}`
            : ""
        }
      />
    </>
  );
};

export default UserType;
