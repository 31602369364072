import moment from "moment";
import serverCall from "../serverCall";




//GetGameDetailSingleId
const GetGameDetailSingleId = async (id) => {
    try {
        const response = await serverCall.post(`/game/admin/cancel-game/${id}`);
        console.log("Facility SingleId response", response);
        return response;
    } catch (error) {
        console.log("FacilitySingleId Api error", error);
    }
};

//UpdateGameDetailRequest
const UpdateGameDetailRequest = async (id, name) => {
    try {
        const response = await serverCall.put(`/game/admin/update/${id}`, name);
        console.log("UpdateGameDetailRequest_response", response);
        return response;
    } catch (error) {
        console.log("UpdateGameDetailRequest Api error", error);
    }
};

//AddPlayerRequest
const AddPlayerRequest = async (gameid, userid) => {
    try {
        const response = await serverCall.post(`/game/admin/add-player?gameId=${gameid}&userID=${userid}`);
        console.log("AddPlayerRequest response", response);
        return response;
    } catch (error) {
        console.log("AddPlayerRequest Api error", error);
    }
};

//GetPlayerRequest
const GetPlayerRequest = async () => {
    try {
        const response = await serverCall.get(`/users/all-user`);
        console.log("GetPlayerRequest response", response);
        return response;
    } catch (error) {
        console.log("GetPlayerRequest error", error);
    }
};

// //DeletePlayerRequest
const DeletePlayerRequest = async (gameid, userid) => {
    try {
        const response = await serverCall.post(`/game/admin/remove-player?gameId=${gameid}&userID=${userid}`);
        console.log("DeletePlayerRequest response", response);
        return response;
    } catch (error) {
        console.log("DeletePlayerRequest Api error", error);
    }
};




const GameDetailData = {
    AddPlayerRequest,
    GetGameDetailSingleId,
    UpdateGameDetailRequest,
    GetPlayerRequest,
    DeletePlayerRequest,
    //     GetFacilityGameSingleId
};

export default GameDetailData;
