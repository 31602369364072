import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const ProfileEditModal = ({ show, handleClose, handleSave, isEdit, cityData }) => {
  const [profileForm, setProfileForm] = useState({
    name: '',
    surname: '',
    mobileNumber: '',
    addressLine1: '',
    addressLine2: '',
    postcode: '',
    state: '',
    area: '',
    email: '',
    education: '',
    country: '',
    region: '',
    experience: '',
    additionalDetails: '',
  });

  useEffect(() => {
    if (isEdit && cityData) {
      setProfileForm({
        ...cityData,
      });
    } else {
      setProfileForm({
        name: '',
        surname: '',
        mobileNumber: '',
        addressLine1: '',
        addressLine2: '',
        postcode: '',
        state: '',
        area: '',
        email: '',
        education: '',
        country: '',
        region: '',
        experience: '',
        additionalDetails: '',
      });
    }
  }, [show, isEdit, cityData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Modal show={show} onHide={handleClose} centered size='lg'>
      <Modal.Header style={{ marginBottom: "0px" }} closeButton>
        <Modal.Title>{isEdit ? 'Update Profile' : 'Create Profile'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" name="name" value={profileForm.name} onChange={handleInputChange} placeholder="Enter name" />
          </Form.Group>
          <Form.Group controlId="surname">
            <Form.Label>Surname</Form.Label>
            <Form.Control type="text" name="surname" value={profileForm.surname} onChange={handleInputChange} placeholder="Enter surname" />
          </Form.Group>
          <Form.Group controlId="mobileNumber">
            <Form.Label>Mobile Number</Form.Label>
            <Form.Control type="text" name="mobileNumber" value={profileForm.mobileNumber} onChange={handleInputChange} placeholder="Enter mobile number" />
          </Form.Group>
          <Form.Group controlId="addressLine1">
            <Form.Label>Address Line 1</Form.Label>
            <Form.Control type="text" name="addressLine1" value={profileForm.addressLine1} onChange={handleInputChange} placeholder="Enter address line 1" />
          </Form.Group>
          <Form.Group controlId="addressLine2">
            <Form.Label>Address Line 2</Form.Label>
            <Form.Control type="text" name="addressLine2" value={profileForm.addressLine2} onChange={handleInputChange} placeholder="Enter address line 2" />
          </Form.Group>
          <Form.Group controlId="postcode">
            <Form.Label>Postcode</Form.Label>
            <Form.Control type="text" name="postcode" value={profileForm.postcode} onChange={handleInputChange} placeholder="Enter postcode" />
          </Form.Group>
          <Form.Group controlId="state">
            <Form.Label>State</Form.Label>
            <Form.Control type="text" name="state" value={profileForm.state} onChange={handleInputChange} placeholder="Enter state" />
          </Form.Group>
          <Form.Group controlId="area">
            <Form.Label>Area</Form.Label>
            <Form.Control type="text" name="area" value={profileForm.area} onChange={handleInputChange} placeholder="Enter area" />
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" name="email" value={profileForm.email} onChange={handleInputChange} placeholder="Enter email" />
          </Form.Group>
          <Form.Group controlId="education">
            <Form.Label>Education</Form.Label>
            <Form.Control type="text" name="education" value={profileForm.education} onChange={handleInputChange} placeholder="Enter education" />
          </Form.Group>
          <Form.Group controlId="country">
            <Form.Label>Country</Form.Label>
            <Form.Control type="text" name="country" value={profileForm.country} onChange={handleInputChange} placeholder="Enter country" />
          </Form.Group>
          <Form.Group controlId="region">
            <Form.Label>Region</Form.Label>
            <Form.Control type="text" name="region" value={profileForm.region} onChange={handleInputChange} placeholder="Enter region" />
          </Form.Group>
          <Form.Group controlId="experience">
            <Form.Label>Experience</Form.Label>
            <Form.Control type="text" name="experience" value={profileForm.experience} onChange={handleInputChange} placeholder="Enter experience" />
          </Form.Group>
          <Form.Group controlId="additionalDetails">
            <Form.Label>Additional Details</Form.Label>
            <Form.Control type="text" name="additionalDetails" value={profileForm.additionalDetails} onChange={handleInputChange} placeholder="Enter additional details" />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={() => handleSave(profileForm)}>
          {isEdit ? 'Update Profile' : 'Add Profile'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProfileEditModal;
