import serverCall from "../serverCall";

// GetAllGameSettings
const GetAllGameSettings = async (page) => {
  try {
    const response = await serverCall.get(`/game-setting`);
    console.log("GetCommunityData response", response);
    return response;
  } catch (error) {
    console.log("GetCommunityData error", error);
  }
};

//CreateGameSettings
const CreateGameSettings = async (id, name) => {
  try {
    const response = await serverCall.post(
      `/game-setting/admin/create/${id}`,
      name
    );
    console.log("CreateCommunityData response", response);
    return response;
  } catch (error) {
    console.log("CommunityDataSingleId Api error", error);
  }
};

//GetGameSettingsSingleId
const GetGameSettingsSingleId = async (id) => {
  try {
    const response = await serverCall.get(`/game-setting/${id}`);
    console.log("GetGameSettings SingleId response", response);
    return response;
  } catch (error) {
    console.log("GetGameSettingsSingleId Api error", error);
  }
};

//DeleteGameSettings
const DeleteGameSettings = async (id) => {
  try {
    const response = await serverCall.delete(`/game-setting/${id}`);
    console.log("DeleteCommunityData response", response);
    return response;
  } catch (error) {
    console.log("DeleteCommunityData Api error", error);
  }
};

//UpdateGameSettings
const UpdateGameSettings = async (id, name) => {
  try {
    const response = await serverCall.put(`/game-setting/${id}`, name);
    console.log("UpdateCommunityData response", response);
    return response;
  } catch (error) {
    console.log("UpdateCommunityData Api error", error);
  }
};

//GameSettingsIDForFacility
const GameSettingsIDForFacility = async (id) => {
  try {
    const response = await serverCall.get(
      `/game-setting/admin/facilities/game-setting/${id}`
    );
    console.log("GameSettingsIDForFacility", response);
    return response;
  } catch (error) {
    console.log("CityIDForFacility Api error", error);
  }
};

//GetGameSettingsDetailsOptions 01
const GetGameSettingsDetailsOptions = async (name) => {
  try {
    const response = await serverCall.post(`/game-setting/get-settings`, name);
    console.log("CreateGameSettingsDetailsOptions response", response);
    return response;
  } catch (error) {
    console.log("CreateGameSettingsDetailsOptions response Api error", error);
  }
};

//UpdateGameSettingsDetailsOptions 02
const UpdateGameSettingsDetailsOptions = async (id, name) => {
  const body = {
    hostedBy: name,
  };
  console.log("bodybodybody", body);
  try {
    const response = await serverCall.put(`/game/admin/update/${id}`, body);
    console.log("UpdateCommunityData response", response);
    return response;
  } catch (error) {
    console.log("UpdateCommunityData Api error", error);
  }
};

const UpdateGameSettingsDetailsOptions2 = async (id, body) => {
  console.log("bodybodybody", body);
  try {
    const response = await serverCall.put(`/game/admin/update/${id}`, body);
    console.log("UpdateCommunityData response", response);
    return response;
  } catch (error) {
    console.log("UpdateCommunityData Api error", error);
  }
};

const GameSettingsData = {
  GetAllGameSettings,
  CreateGameSettings,
  DeleteGameSettings,
  UpdateGameSettings,
  GameSettingsIDForFacility,
  GetGameSettingsSingleId,
  GetGameSettingsDetailsOptions,
  UpdateGameSettingsDetailsOptions,
  UpdateGameSettingsDetailsOptions2,
};

export default GameSettingsData;
