
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeleteFacilitiseModal = ({ show, handleClose, handleDeleteConfirmed, _id, facilityName }) => {
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Delete Facility</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete the facility <strong>{facilityName}</strong>?
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={() => handleDeleteConfirmed(_id)}>
                    Delete
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteFacilitiseModal;
