import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

const PitchTypeEditModal = (props) => {
    const { show, handleClose, handleSave, isEdit, facilitiseSelect, facilitise, setFacilitise, formik, formikErrors } = props


    console.log('formik', formik)

    const [name, setName] = useState('');
    const [minimum, setMinimum] = useState('');
    const [players, setPlayers] = useState('');

    useEffect(() => {
        if (isEdit && facilitiseSelect) {
            setName(facilitiseSelect.name);
            setMinimum(facilitiseSelect.minimumPlayers);
            setPlayers(facilitiseSelect.players);
        } else {
            setName('');
            setMinimum('');
            setPlayers('');
        }
        formik.resetForm()
    }, [show,isEdit, facilitiseSelect]);


    return (
        <Modal size='md' show={show} onHide={handleClose} centered backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Edit Pitch Type' : 'Create Pitch Type'}</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div style={{ justifyContent: "center" }}>
                        <Form.Group controlId="name" style={{}}>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Name"
                                name='name'
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                style={{ width: "100%", textAlign: "center", fontWeight: "bolder" }}
                            />
                            {!isEdit && formik.touched.name && formikErrors.name && (
                                <div className="error-message text-danger">{formikErrors.name}</div>
                            )}
                        </Form.Group>
                        {/* <Row className="mb-3 mt-3" style={{ display: "flex" }}>
                            <Form.Group as={Col} controlId="minimumPlayers">
                                <Form.Label>min</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="12"
                                    name='minimumPlayers'
                                    value={formik.values.minimumPlayers}
                                    onChange={formik.handleChange}

                                    style={{ width: "100%", textAlign: "center", fontWeight: "bolder" }}
                                    min={0}
                                    // max={12}
                                />
                                     {!isEdit && formik.touched.minimumPlayers && formikErrors.minimumPlayers && (
                                <div className="error-message text-danger">{formikErrors.minimumPlayers}</div>
                            )}
                            </Form.Group>
                            <Form.Group as={Col} controlId="players" style={{}}>
                                <Form.Label>max</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="12"
                                    name='players'
                                    value={formik.values.players}
                                    onChange={formik.handleChange}
                                    style={{ width: "100%", textAlign: "center", fontWeight: "bolder" }}
                                    min={0}
                                    // max={12}
                                />
                                     {!isEdit && formik.touched.players && formikErrors.players && (
                                <div className="error-message text-danger">{formikErrors.players}</div>
                            )}
                            </Form.Group>
                        </Row> */}
                         <Row className="mb-3 mt-3" style={{ display: "flex" }}>
      <Form.Group as={Col} controlId="minimumPlayers">
        <Form.Label>Min</Form.Label>
        <Form.Control
          type="number"
          placeholder="0"
          name='minimumPlayers'
          value={formik.values.minimumPlayers}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          style={{ width: "100%", textAlign: "center", fontWeight: "bolder" }}
          min={0}
        />
        {!isEdit && formik.touched.minimumPlayers && formikErrors.minimumPlayers && (
          <div className="error-message text-danger">{formikErrors.minimumPlayers}</div>
        )}
      </Form.Group>
      <Form.Group as={Col} controlId="players" style={{}}>
        <Form.Label>Max</Form.Label>
        <Form.Control
          type="number"
          placeholder="0"
          name='players'
          value={formik.values.players}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          style={{ width: "100%", textAlign: "center", fontWeight: "bolder" }}
          min={0}
        />
        {!isEdit && formik.touched.players && formikErrors.players && (
          <div className="error-message text-danger">{formikErrors.players}</div>
        )}
      </Form.Group>
    </Row>
                    </div>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" type='submit' style={{borderRadius: "10px"}}>
                        {isEdit ? 'Update Pitch Type' : 'Add Pitch Type'}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default PitchTypeEditModal;
