import React, { useEffect, useState } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import axios from 'axios';

const libraries = ['places'];
const mapContainerStyle = {
    width: "100%",
    height: "400px",
};

const Map = ({ setSelectedLocation, setAddress, formik }) => {
    const [clickedLocation, setClickedLocation] = useState(null);
    const [currentLocation, setCurrentLocation] = useState(null);
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyCgMx6dGDaao_AJfu_sua3CEGbkgKfSMIs',
        libraries,
    });

    // useEffect(() => {
    //     if (formik?.values?.coordinates && formik?.values?.coordinates?.length === 2 && formik?.values?.coordinates[0] !== 0 && formik?.values?.coordinates[0] !== "" && formik?.values?.coordinates[1] !== 0 && formik?.values?.coordinates[1] !== "") {
    //         const [lat, lng] = formik?.values?.coordinates;
    //         setCurrentLocation({
    //             lat,
    //             lng,
    //         });
    //         getAddressFromLatLng(lat, lng);
    //     } else {
    //         if (navigator.geolocation) {
    //             navigator.geolocation.getCurrentPosition(
    //                 (position) => {
    //                     const { latitude, longitude } = position.coords;
    //                     setCurrentLocation({
    //                         lat: latitude,
    //                         lng: longitude,
    //                     });
    //                     getAddressFromLatLng(latitude, longitude);
    //                     formik.setFieldValue("coordinates", [latitude, longitude]);
    //                 },
    //                 (error) => {
    //                     console.error("Error getting the current location:", error);
    //                 }
    //             );
    //         }

    //     }
    // }, [formik.values.coordinates]);

    useEffect(() => {
        if (formik?.values?.coordinates && formik?.values?.coordinates?.length === 2 && formik?.values?.coordinates[0] !== 0 && formik?.values?.coordinates[0] !== "" && formik?.values?.coordinates[1] !== 0 && formik?.values?.coordinates[1] !== "") {
            const [lat, lng] = formik?.values?.coordinates;
            setCurrentLocation({
                lat: lng,  // Reversed here
                lng: lat,  // Reversed here
            });
            getAddressFromLatLng(lat, lng);
        } else {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude, longitude } = position.coords;
                        setCurrentLocation({
                            lat: longitude,  // Reversed here
                            lng: latitude,   // Reversed here
                        });
                        getAddressFromLatLng(latitude, longitude);
                        formik.setFieldValue("coordinates", [longitude, latitude]);  // Reversed here
                    },
                    (error) => {
                        console.error("Error getting the current location:", error);
                    }
                );
            }
        }
    }, [formik.values.coordinates]);

    const getAddressFromLatLng = async (lat, lng) => {
        try {
            const response = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCgMx6dGDaao_AJfu_sua3CEGbkgKfSMIs`
            );
            const address = response.data.results[0].formatted_address;
            setAddress(address);
            formik.setFieldValue("address", address);

        } catch (error) {
            console.error("Error fetching address:", error);
        }
    };

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading maps</div>;
    }

    // const handleClick = (e) => {
    //     setCurrentLocation(null);
    //     const lat = e.latLng.lat();
    //     const lng = e.latLng.lng();

    //     setClickedLocation({
    //         lat,
    //         lng,
    //     });

    //     setSelectedLocation({ latitude: lat, longitude: lng });
    //     getAddressFromLatLng(lat, lng);
    //     console.log("Address", lat, lng);
    // };

    const handleClick = (e) => {
        setCurrentLocation(null);
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
    
        setClickedLocation({
            lat,
            lng,
        });
    
        setSelectedLocation({ 
            latitude: lng,  // Reversed here
            longitude: lat   // Reversed here
        });
        getAddressFromLatLng(lat, lng);
    };


    return (
        <div>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                zoom={15}
                center={
                    currentLocation
                        ? currentLocation
                        : clickedLocation || { lat: 0, lng: 0 }
                }
                onClick={handleClick}
            >
                {currentLocation && (
                    <Marker
                        position={{ lat: currentLocation.lat, lng: currentLocation.lng }}
                    />
                )}
                {clickedLocation && (
                    <Marker
                        position={{ lat: clickedLocation.lat, lng: clickedLocation.lng }}
                    />
                )}
            </GoogleMap>
        </div>
    );
};

export default Map;
