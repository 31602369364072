import React from "react";
import { Button, Form, Modal } from "react-bootstrap";

const CheckboxModal = (props) => {
  const {
    onHide,
    handleSubmitCheckbox,
    checkboxOptionSelect,
    users,
    handleUserID,
  } = props;
  return (
    <Modal {...props} centered backdrop="static">
      <Modal.Header>
        <Modal.Title>
          {checkboxOptionSelect.value === "AHEAD"
            ? "Book full pitch"
            : checkboxOptionSelect.value === "CANCELLED"
            ? "Cancel Game"
            : "Advertise Game"}{" "}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="pb-3">
          <Form.Label>Select Player</Form.Label>
          <Form.Select
            aria-label="users list"
            onChange={(e) => handleUserID(e.target.value)}
            // value={selectedUser}
          >
            <option>Select Player</option>
            {users?.length &&
              users?.map((res, index) => {
                return (
                  <option key={index} value={res?._id}>
                    {res?.firstName}
                  </option>
                );
              })}
          </Form.Select>
        </div>
        Are you sure you want to{" "}
        {checkboxOptionSelect.value === "AHEAD"
          ? "book full pitch of"
          : checkboxOptionSelect.value === "CANCELLED"
          ? "cancel"
          : "advertise"}{" "}
        this game?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onHide}>
          Close
        </Button>
        <Button
          style={{
            backgroundColor:
              checkboxOptionSelect.value === "AHEAD"
                ? "#3b4b57"
                : checkboxOptionSelect.value === "CANCELLED"
                ? "red"
                : "green",
          }}
          onClick={handleSubmitCheckbox}
        >
          {checkboxOptionSelect.value === "AHEAD"
            ? "Book Full-pitch"
            : checkboxOptionSelect.value === "CANCELLED"
            ? "Cancel Game"
            : "Advertise"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CheckboxModal;
