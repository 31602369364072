import React, { Fragment, useState } from "react";
import { Button, Col, Form, Row, Container, Card } from "react-bootstrap";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { object, string } from "yup";
import { login, setLoggedIn } from "../../../../../store/slice/auth";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";



const SignIn = () => {
  const [showPassword, setShowPassword] = useState(false);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const formerr = {
    color: "#ff3300",
    fontSize: "16px",
    fontWeight: "500px",
  };

  const schema = object({
    email: string().email().required(" Enter Your Email"),
    password: string().min(1).required(" Enter Your Password"),
  });

  const submit = async (values, action) => {
    const { email, password } = values;
    const greatGameString = localStorage.getItem("userProfile");
    const greatGame = JSON.parse(greatGameString);

    const role = greatGame && greatGame.role;

    try {
      const response = await dispatch(login({ email, password })).unwrap();
      dispatch(setLoggedIn(true));
      localStorage.setItem("isLoggedIn", JSON.stringify(true));



      if ("MASTER-ADMIN" === role) {
        navigate("/cities/");
      }
      else if ("ADMIN" === role) {
        navigate("/games/");
      }


      enqueueSnackbar("Login Successfully!", { variant: "success" });
      // navigate(`/cities/`);
      action.resetForm();
      window.location.reload();
    } catch (e) {
      navigate("/login");
      enqueueSnackbar("Invalid credentials ", { variant: "error" });
    }
  };

  const initialValue = {
    email: "",
    password: "",
  };

  const { errors, touched, handleSubmit, values, handleChange } = useFormik({
    initialValues: initialValue,
    validationSchema: schema,
    onSubmit: submit,
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleForgetPassword = () => { 
    alert("Forgot Password");
   };

  return (
    <>
      <Fragment>
        <div className="mypage page-signin main-signin-wrapper">
          <Row className="signpages text-center">
            <Col md={12}>
              <Card style={{
                WebkitBoxShadow: '-4px -4px 130px 23px rgba(0,0,0,0.75)',
                MozBoxShadow: '-4px -4px 130px 23px rgba(0,0,0,0.75)',
                boxShadow: '-4px -4px 130px 23px rgba(0,0,0,0.75)'
              }}>
                <Row className="row-sm">
                  <Col
                    lg={6}
                    xl={5}
                    className="d-none d-lg-block text-center bg-primary details"
                  >
                    <div className="mt-4 pt-4 p-2 pos-absolute">
                      <h3 style={{ color: "#FFFCFD", fontSize: "40px", fontFamily: "Zilla Slab, serif", marginBottom: "20px" }}>Great Game</h3>

                      <div className="clearfix"></div>
                      <img
                        src={require("../../../../../assets/img/media/GreatGameAdmin.png")}
                        className="ht-100 mb-0"
                        alt="user"
                      />
                      <h3 className="mt-4 text-white" style={{ fontFamily: "Zilla Slab, serif" }}>Sign-In</h3>
                      <span className="tx-white-6 tx-13 mb-5 mt-xl-0" >
                        Sign-in to create, discover and connect with the Great Game
                        community
                      </span>
                    </div>
                  </Col>
                  <Col lg={6} xl={7} xs={12} sm={12} className="login_form ">
                    <Container fluid>
                      <Row className="row-sm">
                        <Card.Body className="mt-2 mb-2">
                          <img
                            src={require("../../../../../assets/img/media/GreatGameAdmin.png")}
                            className="ht-70 p-2 d-lg-none header-brand-img text-start float-start mb-19 auth-light-logo"
                            alt="logo"
                          />
                          {/* <img
                            src={require("../../assets/img/brand/logo-light.png")}
                            className=" d-lg-none header-brand-img text-start float-start mb-4 auth-dark-logo"
                            alt="logo"
                          /> */}
                          <form onSubmit={handleSubmit}>
                            <h5 className="text-start mb-2" style={{ fontFamily: "Zilla Slab, serif", fontWeight: "bolder" }}>
                              Sign-in to Your Account
                            </h5>
                            <p className="mb-4 text-muted tx-13 ms-0 text-start">
                              Sign-In to create, discover and connect with the
                              Great Game community
                            </p>
                            <Form.Group
                              className="text-start form-group mt-4"
                              controlId="formEmail"
                            >
                              <Form.Label style={{ marginTop: '15px' }}>Email</Form.Label>        {/* E-MAIL */}
                              <Form.Control
                                className="form-control"
                                placeholder="Enter your Email"
                                name="email"
                                type="email"
                                value={values.email}
                                onChange={handleChange}
                              />
                              {errors.email && touched.email ? (
                                <p style={formerr}>{errors.email}</p>
                              ) : null}
                            </Form.Group>

                            {/* PASS-WORD */}

                            {/* <Form.Group
                              className="text-start form-group"
                              controlId="formpassword"
                            >
                              <Form.Label style={{ marginTop: '15px' }}>Password</Form.Label>    
                              <Form.Control
                                className="form-control"
                                placeholder="Enter your password"
                                name="password"
                                type="password"
                                value={values.password}
                                onChange={handleChange}
                              />
                              {errors.password && touched.password ? (
                                <p style={formerr}>{errors.password}</p>
                              ) : null}
                            </Form.Group> */}

                            <Form.Group
                              className="text-start form-group"
                              controlId="formpassword"
                            >
                              <Form.Label className="text-dark">
                                {("Password")}
                              </Form.Label>
                              <div
                                className="d-flex"
                                style={{
                                  position: "relative",
                                  display: "flex",
                                }}
                              >
                                <Form.Control
                                  className="form-control"
                                  placeholder="Enter your Password"
                                  name="password"
                                  type={showPassword ? "text" : "password"}
                                  value={values.password}
                                  onChange={handleChange}
                                />
                                <i
                                  style={{
                                    position: "absolute",
                                    top: "30%",
                                    right: "4%",
                                  }}
                                  onClick={togglePasswordVisibility}
                                  className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"
                                    } text-dark`}
                                ></i>

                              </div>

                              {errors.password && touched.password ? (
                                <p style={formerr}>{errors.password}</p>
                              ) : null}
                            </Form.Group>

                            <Button
                              style={{ backgroundColor: "#00ADEF", borderRadius: "5px" }}
                              type="submit"
                              className="btn ripple btn-main-primary btn-block mt-3"
                            >
                              Sign In
                            </Button>
                          </form>
                          <div className="text-start mt-4 ms-0">
                            <div className="mb-1">
                              <Link to="#" onClick={handleForgetPassword} > Forgot password ?</Link>
                            </div>
                          </div>
                        </Card.Body>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </Fragment>
    </>
  );
};

SignIn.propTypes = {};

SignIn.defaultProps = {};

export default SignIn;
