import serverCall from "../serverCall";

const GetAllRepeatedBookings = async () => {
  try {
    const response = await serverCall.post("/game/admin/get-all");
    return response?.data;
  } catch (error) {
    console.log("GetAllRepeatedBookings Api error", error);
  }
};

export default GetAllRepeatedBookings;
