import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import * as Yup from "yup";
import Select from "react-select";
import { ErrorMessage } from "formik";

const UserTypeEditModal = (props) => {
  const {
    show,
    handleClose,
    handleSave,
    isEdit,
    formik,
    facilitiseSelect,
    formikErrors,
  } = props;
  console.log("formik", formik);
  const [userType, setUserType] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  useEffect(() => {
    console.log("User", isEdit);
    if (isEdit && facilitiseSelect) {
      setUserType(facilitiseSelect.roles);
      setEmail(facilitiseSelect.email);
      setPassword(facilitiseSelect.password);
      setFirstName(facilitiseSelect.firstName);
      setLastName(facilitiseSelect.lastName);
    } else {
      setUserType("");
      setEmail("");
      setPassword("");
    }
    formik.resetForm();
  }, [isEdit, facilitiseSelect]);

  const [generatedPassword, setGeneratedPassword] = useState(
    Math.random().toString(36).slice(-8)
  );

  const generateRandomPassword = (setFieldValue) => {
    const randomPassword = Math.random().toString(36).slice(-8);
    formik.setFieldValue("password", randomPassword);
    setGeneratedPassword(randomPassword);
  };

  const opt = [
    { value: "USER", label: "USER" },
    { value: "HOST", label: "HOST" },
    { value: "ADMIN", label: "ADMIN" },
  ];

  return (
    <Modal
      size="md"
      show={show}
      onHide={handleClose}
      centered
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isEdit ? "Edit User List" : "Create User List"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="roles">
              <Form.Label>User Type</Form.Label>
              <Select
                name="roles"
                placeholder="Select user type"
                options={opt}
                value={opt.find((item) => item.value === formik.values.roles)}
                onChange={(selectedOption) => {
                  formik.setFieldValue("roles", selectedOption.value);
                }}
              />
              {!isEdit && formik.touched.roles && formikErrors.roles && (
                <div className="error-message text-danger">
                  {formikErrors.roles}
                </div>
              )}
            </Form.Group>
          </Row>

          <Row className="mb-3 mt-3" style={{ display: "flex" }}>
            <Form.Group as={Col} controlId="firstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                style={{ width: "100%", textAlign: "center" }}
                min={0}
                max={12}
              />
            </Form.Group>
            <Form.Group as={Col} controlId="lastName" style={{}}>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                name="lastName"
                value={formik.values.lastName}
                onChange={formik.handleChange}
                style={{ width: "100%", textAlign: "center" }}
                min={0}
                max={12}
              />
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col} controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                placeholder="Enter Email here"
                isInvalid={formik.touched.email && formik.errors.email}
              />
              {formik.touched.email && formik.errors.email && (
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                  }}
                >
                  <Button
                    variant="link"
                    onClick={() => formik.setFieldValue("email", "")}
                  >
                    <i
                      className="bi bi-x-circle-fill"
                      style={{ color: "red" }}
                    ></i>
                  </Button>
                </div>
              )}
              {!isEdit && formik.touched.email && formikErrors.email && (
                <div className="error-message text-danger">
                  {formikErrors.email}
                </div>
              )}
            </Form.Group>

            <Form.Group as={Col} controlId="password">
              <Form.Label>Password</Form.Label>
              <Row>
                <Col>
                  <Form.Control
                    type={isEdit ? "password" : "text"}
                    name="password"
                    placeholder="Enter Password here"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    style={{ width: "150%" }}
                  />
                  {!isEdit &&
                    formik.touched.password &&
                    formikErrors.password && (
                      <div className="error-message text-danger">
                        {formikErrors.password}
                      </div>
                    )}
                </Col>
                <Col>
                  {!isEdit && (
                    <Button
                      size="sm"
                      style={{ marginLeft: "25%" }}
                      variant="secondary"
                      onClick={() =>
                        generateRandomPassword(formik.setFieldValue)
                      }
                    >
                      Generate
                    </Button>
                  )}
                </Col>
              </Row>
            </Form.Group>
          </Row>

          <Row style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="primary"
              type="submit"
              style={{ width: "30%", borderRadius: "10px" }}
            >
              {isEdit ? "Update User List" : "Add User List"}
            </Button>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UserTypeEditModal;
