import { get } from "lodash";
import customAxios from "../serverCall";

const signUp = (userSignInRequest) => {
  const { email, password } = userSignInRequest;
  return customAxios
    .post(`/auth/admin/login?email=${email}&password=${password}`)
    .then((response) => {
      const apiData = get(response, "data", null);
      console.log("apiData", apiData);
      if (apiData) {
        const email = apiData.user.email;
        const token = apiData.accessToken;
        const id = apiData.user.id;
        const role = apiData.user.roles;
        const facility_id = apiData.user?.facility?._id
        const facilityName = apiData.user?.facility?.name
        // localStorage.setItem("token", token);
        const userAndToken = {
          email,
          token,
          id,
          role,
          facility_id,
          facilityName
        };
        localStorage.setItem("userProfile", JSON.stringify(userAndToken));
      }
      return apiData;
    });
};

const AuthenticationService = {
  signUp,
};

export default AuthenticationService;