import { Link } from '@material-ui/core';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="main-footer text-center">
      <Container>
        <Row className="row-sm">
          <Col md={12} className="col-md-12">
            <span style={{ color: "black",cursor:"pointer" }}>Copyright © 2022 <Link onClick={() => navigate("/")} style={{ color: "black" }}>Great Game</Link>. Designed by <Link onClick={() => navigate("/")} style={{ color: "black" }}>Great Game</Link> All rights reserved.</span>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
