import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeleteCityModal = ({ show, handleClose, handleConfirmDelete, _id, cityName }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header >
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete <strong>{cityName}</strong> ?
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="danger" onClick={() => handleConfirmDelete(_id)}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


export default DeleteCityModal;
