import serverCall from "../serverCall";

//getTeamData
const getTeamData = async (page, dataPerPage) => {
  try {
    const response = await serverCall.get(
      `/team/getall/admin?page=${page}&limit=${dataPerPage}`
    );
    console.log("get TeamData response", response);
    return response;
  } catch (error) {
    console.log("get TeamData error", error);
  }
};

//CreateCityData
// const CreateCityData = async (name) => {
//     console.log("create city data", name);
//     try {
//         const response = await serverCall.post(
//             `/city/admin/create`, { name });
//         console.log("Create CityDataApi response", response);
//         return response;
//     } catch (error) {
//         console.log("getCreateCityDataApi error", error);
//     }
// };

//GetTeamSingleId
const GetTeamSingleId = async (id) => {
  try {
    const response = await serverCall.get(`/team/get/${id}`);
    console.log("GetTeamSingleId response", response);
    return response;
  } catch (error) {
    console.log("GetTeamSingleId Api error", error);
  }
};

// UpdateCityDataRequest
const UpdateTeamRequest = async (id, name) => {
  try {
    const response = await serverCall.put(`/team/${id}`, { name });
    console.log("UpdateTeamRequest response", response);
    return response;
  } catch (error) {
    console.log("UpdateTeamRequest Api error", error);
  }
};

//DeleteTeamDataRequest
const DeleteTeamDataRequest = async (id) => {
  try {
    const response = await serverCall.delete(`/team/delete/admin?teamid=${id}`);
    console.log("DeleteTeamDataRequest response", response);
    return response;
  } catch (error) {
    console.log("DeleteTeamDataRequest Api error", error);
  }
};

//CityIDForFacility
// const CityIDForFacility = async (id) => {
//     try {
//         const response = await serverCall.get(`/city/admin/facilities/${id}`);
//         console.log("CityIDForFacility response", response);
//         return response;
//     } catch (error) {
//         console.log("CityIDForFacility Api error", error);
//     }
// };

const TeamData = {
  getTeamData,
  GetTeamSingleId,
  DeleteTeamDataRequest,
  UpdateTeamRequest,
};

export default TeamData;
