import React, { useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
// import DeletePitchTypeModal from "./DeletePitchTypeModal";
// import PitchTypeEditModal from "./PitchTypeEditModal";
import axios from "axios";
import PitchTypeService from "../../../../../services/pitchType.service";
import { useFormik } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import { enqueueSnackbar } from "notistack";
import PitchNameService from "../../../../../services/pitchName.service";
import GetAllRepeatedBookings from "../../../../../services/repeatedBooking";

const RepeatedBooking = () => {
  const [data, setData] = useState([]);
  const [pitchName, setPitchName] = useState([]);
  const [pitchType, setPitchType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPitchNameModal, setShowPitchNameModal] = useState(false);
  const [showDeletePitchModal, setShowDeletePitchModal] = useState(false);
  const [selectedPitch, setSelectedPitch] = useState(null);
  const [scroll, setScroll] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [formikErrors, setFormikErrors] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  const handleClosePitchNameModal = () => {
    setShowPitchNameModal(false);
    setSelectedPitch(null);
  };

  const handleFormSubmit = async (values, action) => {
    console.log("handleFormSubmit", values);
    if (!values._id) {
      try {
        const response = await PitchTypeService.CreatePitchType(values);
        action.resetForm();
        setShowPitchNameModal(false);
        enqueueSnackbar(response.data.message, {
          variant: "success",
          autoHideDuration: 4000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        action.resetForm();
        console.log("response", response);
      } catch (e) {
        console.log("e", e);
        enqueueSnackbar(e.response.data.message, {
          variant: "error",
          autoHideDuration: 4000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } else {
      try {
        const response = await PitchTypeService.UpdatePitchTypeRequest(
          values._id,
          values
        );
        console.log("eresponse", response);
        action.resetForm();
        setShowPitchNameModal(false);
        enqueueSnackbar(response.data.message, {
          variant: "success",
          autoHideDuration: 4000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        console.log("Update", response.data);
      } catch (e) {
        enqueueSnackbar(e.response.data.message, {
          variant: "error",
          autoHideDuration: 4000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        console.log("e", e);
      }
    }
    // getPitchTypeData();
    getAllRepeatedBooking();
  };

  const handleEditPitch = async (id) => {
    console.log("pitch", id);
    setSelectedPitch(id);
    setShowPitchNameModal(true);
    if (id) {
      console.log("id", id);
      const response = await PitchTypeService.GetPitchTypeSingleId(id);
      const result = response.data.data;
      formik.setFieldValue("name", result.name);
      formik.setFieldValue("_id", result._id);
      formik.setFieldValue("players", result.players);
      formik.setFieldValue("minimumPlayers", result.minimumPlayers);
    } else {
      formik.setFieldValue("name", "");
      formik.setFieldValue("players", "");
      formik.setFieldValue("minimumPlayers", "");
      formik.resetForm();
    }
  };

  const handleDeletePitch = (_id, name) => {
    console.log("Confirm Delete", _id);
    setDeletingIndex(_id);
    setShowDeletePitchModal(true);
    setSelectedPitch({ id: _id, name: name });
  };

  const handleConfirmDelete = async (id) => {
    try {
      const response = await PitchTypeService.DeletePitchTypeRequest(id);
      console.log("Confirm Delete", response);
      setShowDeletePitchModal(false);
      // getPitchTypeData();
      getAllRepeatedBooking();

      enqueueSnackbar(response.data.message, {
        variant: "error",
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (e) {
      console.log(e.response.data.message);
      enqueueSnackbar("Error deleting city", {
        variant: "error",
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      players: "",
      minimumPlayers: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Pitch Type is required"),
      players: Yup.number().required("Max Player is required"),
      minimumPlayers: Yup.number().required("Min Player is required"),
    }),
    validate: (values) => {
      const errors = {};

      if (
        values.minimumPlayers &&
        values.players &&
        Number(values.minimumPlayers) > Number(values.players)
      ) {
        errors.minimumPlayers =
          "Minimum players should not be greater than players";
      }

      setFormikErrors(errors);
      return errors;
    },
    onSubmit: handleFormSubmit,
  });

  const columns = [
    {
      name: "Name",
      selector: (row) => {
        const fullName = `${row?.firstName} ${row?.lastName}`.trim();
        return fullName;
      },
      sortable: true,
    },
    {
      name: "Pitch",
      selector: (row) => {
        const data = pitchName?.find((res) => res?._id == row?.pitch);
        return data?.name;
      },
      sortable: true,
    },
    {
      name: "Pitch Type",
      selector: (row) => {
        const data = pitchType?.find((res) => res?._id == row?.pitchTypeId);
        return data?.name;
      },
      sortable: true,
    },
    {
      name: "IsRepeated",
      selector: (row) => (row?.isRepeated ? "YES" : "NO"),
      sortable: true,
    },
    {
      name: "status",
      selector: (row) => row?.status,
      sortable: true,
    },
    {
      name: "RepeatPayment",
      selector: (row) => (row?.repeatPayment ? "YES" : "NO"),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Button
            size="sm"
            variant="info"
            // onClick={() => handleEditPitch(row._id)}
          >
            <i className="fe fe-edit"></i>
          </Button>
          <Button
            size="sm"
            variant="danger"
            // onClick={() => handleDeletePitch(row._id, row.name)}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
    },
  ];

  //TEXT SEARCH CHNAGES
  const filteredData = data.filter((item) => {
    const searchQueryLower = searchQuery?.toLowerCase();

    return (
      item.firstName?.toLowerCase().includes(searchQueryLower) ||
      item.lastName?.toLowerCase().includes(searchQueryLower) ||
      item.pitch?.toLowerCase().includes(searchQueryLower) ||
      item.pitchTypeId?.toLowerCase().includes(searchQueryLower) ||
      // item.isRepeated?.toString().toLowerCase().includes(searchQueryLower) ||
      // item.repeatPayment?.toString().toLowerCase().includes(searchQueryLower) ||
      item.status?.toLowerCase().includes(searchQueryLower)
    );
  });

  // getAll Repeated Bookings
  const getAllRepeatedBooking = async () => {
    try {
      const response = await GetAllRepeatedBookings();
      setData(response?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  // getAll Pitch Name
  const getPitchName = async () => {
    try {
      const response = await PitchNameService.getPitchName();
      setPitchName(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // getAll Pitch Type
  const getPitchType = async () => {
    try {
      const response = await PitchTypeService.getPitchType();
      console.log(response.data.data);
      setPitchType(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllRepeatedBooking();
    getPitchName();
    getPitchType();
  }, []);
  
  useEffect(() => {
    if (scroll) {
      window.scrollTo(0, 0);
    }
  }, [scroll]);

  return (
    <>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Manage Repeated Booking
          </h2>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 mb-4">
          <Card>
            <Card.Body>
              <div className="d-flex mb-3" style={{ float: "right" }}>
                <input
                  type="text"
                  placeholder="Search Here..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="form-control"
                  style={{ marginRight: "10px", width: "100%" }}
                />
                <Button
                  size="sm"
                  variant="primary"
                  onClick={() => setSearchQuery("")}
                  style={{ borderRadius: "5px" }}
                >
                  Clear
                </Button>
              </div>
              <DataTable
                columns={columns}
                data={filteredData.reverse()}
                pagination
                paginationPerPage={10}
                progressPending={loading}
              />
            </Card.Body>
          </Card>
        </div>
      </div>

      {/* <PitchTypeEditModal
        show={showPitchNameModal}
        handleClose={handleClosePitchNameModal}
        handleSave={formik.handleSubmit} 
        isEdit={selectedPitch !== null} 
        facilitiseSelect={selectedPitch} 
        selectedPitch={selectedPitch}
        formik={formik}
        formikErrors={formik.errors}
      />

      <DeletePitchTypeModal
        show={showDeletePitchModal}
        handleClose={() => setShowDeletePitchModal(false)}
        handleConfirmDelete={handleConfirmDelete}
        _id={deletingIndex}
        pitchName={selectedPitch ? selectedPitch.name : ""}
      /> */}
    </>
  );
};

export default RepeatedBooking;
