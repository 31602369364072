import React, { useState, useEffect } from "react";
import { Button, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DeletePitchTypeModal from "./DeletePitchTypeModal";
import PitchTypeEditModal from "./PitchTypeEditModal";
import axios from "axios";
import PitchTypeService from "../../../../../services/pitchType.service";
import { useFormik } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import { enqueueSnackbar } from "notistack";

const PitchType = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPitchNameModal, setShowPitchNameModal] = useState(false);
  const [showDeletePitchModal, setShowDeletePitchModal] = useState(false);
  const [selectedPitch, setSelectedPitch] = useState(null);
  const [scroll, setScroll] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [formikErrors, setFormikErrors] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  const getPitchTypeData = async () => {
    try {
      setLoading(true);
      const response = await PitchTypeService.getPitchType();
      console.log("PitchType GetAll Page", response);
      setData(response.data["data"]);

      setLoading(false);
      setLoadingData(false);
      // enqueueSnackbar(response.data.message, {
      //   variant: "success", autoHideDuration: 1000, anchorOrigin: {
      //     vertical: "bottom", horizontal: "right"
      //   }
      // })
    } catch (error) {
      console.log("GetAll PitchType errrrrr", error);
      setLoading(false);
      setLoadingData(false);
    }
  };
  useEffect(() => {
    getPitchTypeData();
  }, []);

  useEffect(() => {
    if (scroll) {
      window.scrollTo(0, 0);
    }
  }, [scroll]);

  const handleClosePitchNameModal = () => {
    setShowPitchNameModal(false);
    setSelectedPitch(null);
  };

  const handleFormSubmit = async (values, action) => {
    console.log("handleFormSubmit", values);
    if (!values._id) {
      try {
        const response = await PitchTypeService.CreatePitchType(values);
        action.resetForm();
        setShowPitchNameModal(false);
        enqueueSnackbar(response.data.message, {
          variant: "success",
          autoHideDuration: 4000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        action.resetForm();
        console.log("response", response);
      } catch (e) {
        console.log("e", e);
        enqueueSnackbar(e.response.data.message, {
          variant: "error",
          autoHideDuration: 4000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } else {
      try {
        const response = await PitchTypeService.UpdatePitchTypeRequest(
          values._id,
          values
        );
        console.log("eresponse", response);
        action.resetForm();
        setShowPitchNameModal(false);
        enqueueSnackbar(response.data.message, {
          variant: "success",
          autoHideDuration: 4000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        console.log("Update", response.data);
      } catch (e) {
        enqueueSnackbar(e.response.data.message, {
          variant: "error",
          autoHideDuration: 4000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        console.log("e", e);
      }
    }
    getPitchTypeData();
  };

  const handleEditPitch = async (id) => {
    console.log("pitch", id);
    setSelectedPitch(id);
    setShowPitchNameModal(true);
    if (id) {
      console.log("id", id);
      const response = await PitchTypeService.GetPitchTypeSingleId(id);
      const result = response.data.data;
      formik.setFieldValue("name", result.name);
      formik.setFieldValue("_id", result._id);
      formik.setFieldValue("players", result.players);
      formik.setFieldValue("minimumPlayers", result.minimumPlayers);
    } else {
      formik.setFieldValue("name", "");
      formik.setFieldValue("players", "");
      formik.setFieldValue("minimumPlayers", "");
      formik.resetForm();
    }
  };

  const handleDeletePitch = (_id, name) => {
    console.log("Confirm Delete", _id);
    setDeletingIndex(_id);
    setShowDeletePitchModal(true);
    setSelectedPitch({ id: _id, name: name });
  };

  const handleConfirmDelete = async (id) => {
    try {
      const response = await PitchTypeService.DeletePitchTypeRequest(id);
      console.log("Confirm Delete", response);
      setShowDeletePitchModal(false);
      getPitchTypeData();

      enqueueSnackbar(response.data.message, {
        variant: "error",
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (e) {
      console.log(e.response.data.message);
      enqueueSnackbar("Error deleting city", {
        variant: "error",
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      players: "",
      minimumPlayers: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Pitch Type is required"),
      players: Yup.number().required("Max Player is required"),
      minimumPlayers: Yup.number().required("Min Player is required"),
    }),
    validate: (values) => {
      const errors = {};

      if (
        values.minimumPlayers &&
        values.players &&
        Number(values.minimumPlayers) > Number(values.players)
      ) {
        errors.minimumPlayers =
          "Minimum players should not be greater than players";
      }

      setFormikErrors(errors);
      return errors;
    },
    onSubmit: handleFormSubmit,
  });

  const columns = [
    {
      name: "ID",
      selector: (row) => row._id,
      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row) => {
        const date = new Date(row.updatedAt);
        return date.toLocaleDateString("en-GB");
      },
      sortable: true,
    },
    {
      name: "Pitch Type",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Minimum Player",
      selector: (row) => row.minimumPlayers,
      sortable: true,
    },
    {
      name: "Maximum Player",
      selector: (row) => row.players,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Button
            size="sm"
            variant="info"
            onClick={() => handleEditPitch(row._id)}
          >
            <i className="fe fe-edit"></i>
          </Button>
          <Button
            size="sm"
            variant="danger"
            onClick={() => handleDeletePitch(row._id, row.name)}
          >
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      ),
    },
  ];

  //TEXT SEARCH CHNAGES
  const filteredData = data.filter((item) => {
    return (
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item._id.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.players
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      item.minimumPlayers
        .toString()
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      new Date(item.updatedAt)
        .toLocaleDateString("en-GB")
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    );
  });

  return (
    <>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Pitch Types</h2>
        </div>

        <div className="d-flex">
          <div className="justify-content-center">
            <Button
              variant="primary"
              style={{ borderRadius: "5px" }}
              type="button"
              className="my-2 btn-icon-text"
              onClick={() => setShowPitchNameModal(true)}
            >
              <i className="fe fe-plus me-2"></i>Create Pitch Type
            </Button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 mb-4">
          <Card>
            <Card.Body>
              <div className="d-flex mb-3" style={{ float: "right" }}>
                <input
                  type="text"
                  placeholder="Search Pitch Type..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="form-control"
                  style={{ marginRight: "10px", width: "100%" }}
                />
                <Button
                  size="sm"
                  variant="primary"
                  onClick={() => setSearchQuery("")}
                  style={{ borderRadius: "5px" }}
                >
                  Clear
                </Button>
              </div>
              <DataTable
                columns={columns}
                data={filteredData.reverse()}
                pagination
                paginationPerPage={10}
                progressPending={loading}
              />
            </Card.Body>
          </Card>
        </div>
      </div>

      <PitchTypeEditModal
        show={showPitchNameModal}
        handleClose={handleClosePitchNameModal}
        handleSave={formik.handleSubmit} // Pass the handleSave function
        isEdit={selectedPitch !== null} // Check if there's a selected pitch for editing
        facilitiseSelect={selectedPitch} // Pass the selected pitch if it's for editing
        selectedPitch={selectedPitch}
        formik={formik}
        formikErrors={formik.errors}
      />

      <DeletePitchTypeModal
        show={showDeletePitchModal}
        handleClose={() => setShowDeletePitchModal(false)}
        handleConfirmDelete={handleConfirmDelete}
        _id={deletingIndex}
        pitchName={selectedPitch ? selectedPitch.name : ""}
      />
    </>
  );
};

export default PitchType;
