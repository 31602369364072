import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import AuthLogin from "../app/pages/components/Admin/Authentication/Login";
import { lazy } from "react";
import MatxLayout from "../app/pages/components/maxLayout";
import NotFound from "../layouts/sessions/NotFound";
import Loader from "../layouts/Loader/Loader";
import Cities from "../app/pages/components/Admin/Cities/Cities";
import Facilitise from "../app/pages/components/Admin/Facilitise/Facilitise.js";
import PitchName from "../app/pages/components/Admin/PitchName/PitchName.js";
import Game from "../app/pages/components/Admin/Game/Game.js";
import GameDetail from "../app/pages/components/Admin/GameDetail/GameDetail.js";
import UserType from "../app/pages/components/Admin/UserList/UserType.js";
import GameSettings from "../app/pages/components/Admin/Game Settings/GameSettings.js";
import UserTypeViewModal from "../app/pages/components/Admin/UserList/UserTypeViewModal.js";
import Faq from "../app/pages/components/Admin/Faq/Faq.js";
import PitchType from "../app/pages/components/Admin/PitchType/PitchType.js";
import Wallet from "../app/pages/components/FacilityAdmin/Wallet/Wallet.js";
import BankAccount from "../app/pages/components/FacilityAdmin/BankAccounts/BankAccount.js";
import Profile from "../app/pages/components/PlayerAdmin/Profile/Profile.js";
import GameFacilityDetail from "../app/pages/components/FacilityAdmin/GameDetailFacility/GameFacilityDetail.js";
import FacilityGames from "../app/pages/components/FacilityAdmin/GameFacility/FacilityGames.js";
import Community from "../app/pages/components/Admin/Community/Community.js";
import Team from "../app/pages/components/Admin/Teams/Team.js";
import RepeatedBooking from "../app/pages/components/Admin/RepeatedBooking/RepeatedBooking.js";

const Loadable = (Component) => (props) => {
  return (
    <React.Suspense fallback={<Loader />}>
      <Component {...props} />
    </React.Suspense>
  );
};

// const Dashboard = Loadable(
//   lazy(() => import("../app/pages/components/Admin/Cities/Cities.js"))
// );
function RedirectionWrapper({ to }) {
  const queryString = window.location.search;
  if (queryString) {
    return <Navigate to={`${to}${queryString}`} />;
  }
  return <Navigate to={to} />;
}

const greatGameString = localStorage.getItem("userProfile");
const greatGame = JSON.parse(greatGameString);

const role = greatGame && greatGame.role;

const routes = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? <MatxLayout /> : <RedirectionWrapper to="/login" />,
    children: [
      {
        element: (
          <>
            {role === "ADMIN" && <RedirectionWrapper to="/games/" />}
            {role === "MASTER-ADMIN" && <RedirectionWrapper to="/cities/" />}
            {role === "DEALER" && (
              <RedirectionWrapper to="/paiddealerdashboard/" />
            )}
            {role === "DEALER" && (
              <RedirectionWrapper to="/freedealerdashboard/" />
            )}
            {role === "CHILD_BUSINESS_PARTNER" && (
              <RedirectionWrapper to="/childbusinesspartnerparkhistory/" />
            )}
          </>
        ),
        index: true,
      },
      {
        path: "/cities/",
        element: <Cities />,
      },
      {
        path: "/cities/facilitise/details/:id",
        element: <Facilitise />,
      },
      {
        path: "/cities/facilitise/details/gamesettings/game/:id",
        element: <Game />,
      },
      {
        path: "/cities/facilitise/details/game/gamedetail/:id",
        element: <GameDetail />,
      },
      {
        path: "/games/cities/facilitise/details/game/gamedetail/:id",
        element: <GameDetail />,
      },
      {
        path: "/userlist/",
        element: <UserType />,
      },
      {
        path: "/userlist/userview/:id",
        element: <UserTypeViewModal />,
      },
      {
        path: "/pitchname/",
        element: <PitchName />,
      },
      {
        path: "/pitchtype/",
        element: <PitchType />,
      },
      {
        path: "/cities/facilitise/details/gamesettings/:id",
        element: <GameSettings />,
      },
      {
        path: "/community/",
        element: <Community />,
      },
      {
        path: "/teams/",
        element: <Team />,
      },
      {
        path: "/gamefaq/",
        element: <Faq />,
      },
      {
        path: "/manage_repeated_booking/",
        element: <RepeatedBooking />,
      },
      {
        path: "/gamewallet/",
        element: <Wallet />,
      },
      {
        path: "/games/",
        element: <FacilityGames />,
      },
      {
        path: "/bankaccount/",
        element: <BankAccount />,
      },
      {
        path: "/profile/",
        element: <Profile />,
      },
      {
        path: "/games/gamefacilitydetail/:id",
        element: <GameFacilityDetail />,
      },
    ],
  },
  {
    path: "/login",
    element: !isLoggedIn ? (
      <AuthLogin />
    ) : (
      <>
        {role === "ADMIN" && <RedirectionWrapper to="/games/" />}
        {role === "MASTER-ADMIN" && <RedirectionWrapper to="/cities/" />}
        {role === "DEALER" && <RedirectionWrapper to="/paiddealerdashboard/" />}
        {role === "DEALER" && <RedirectionWrapper to="/freedealerdashboard/" />}
        {role === "CHILD_BUSINESS_PARTNER" && (
          <RedirectionWrapper to="/childbusinesspartnerparkhistory/" />
        )}
      </>
    ),
  },
  { path: "*", element: <NotFound /> },
];

export default function Routes(props) {
  const { isLoggedIn } = props;
  return useRoutes(routes(isLoggedIn));
}
