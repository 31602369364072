import React from "react";
import { Modal, Button, Form } from "react-bootstrap";

const CreateCityModal = ({
  show,
  handleClose,
  handleSave,
  isEdit,
  cityData,
  formik,
}) => {
  const { values, errors, touched, handleChange, handleSubmit } = formik;

  const onSaveClick = () => {
    handleSave(values);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header
        style={{ marginBottom: "0px" }}
        closeButton
        onClick={() => formik.resetForm()}
      >
        <Modal.Title>{isEdit ? "Edit City" : "Create City"}</Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <Form.Group controlId="cityName">
            <Form.Label>City Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={values.name}
              onChange={handleChange}
              placeholder="Enter city name"
              isInvalid={touched.name && errors.name}
              style={{ textAlign: "center", fontWeight: "bolder" }}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="isPublish" style={{ marginTop: "10px" }}>
            <Form.Check
              type="checkbox"
              label="Publish City"
              name="isPublish"
              checked={values.isPublish}
              onChange={handleChange}
              style={{
                fontWeight: "bolder",
                display: "flex",
                alignItems: "center",
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" type="submit">
            {isEdit ? "Update City" : "Add City"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CreateCityModal;
