import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const DeleteBankModal = ({ show, handleClose, handleConfirmDelete, bankName, _id }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete the bank of <strong>{bankName}</strong> ?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger"  onClick={() => handleConfirmDelete(_id)}>
          Delete Bank Account
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteBankModal;
