import serverCall from "../serverCall";

// //getUserListData
// const getUserListData = async (search,page) => {
//     try {
//         // const response = await serverCall.post(`/admin/user/get-all?search=${search}`);
//         const response = await serverCall.post(`/admin/user/get-all?page=${page}&limit=${10}&search=${search}`);
//         console.log("get_UserListData_response", response);
//         return response;
//     } catch (error) {
//         console.log("get UserListData error", error);
//     }
// };

// //CreateUserList
// const CreateUserList = async (name) => {
//     console.log("create city data", name);
//     try {
//         const response = await serverCall.post(`/admin/user-create`, name);
//         console.log("Create CityDataApi response", response);
//         return response;
//     } catch (error) {
//         console.log("getCreateCityDataApi error", error);
//     }
// };

//GetWalletListData
const GetWalletListData = async (id) => {
  try {
    const response = await serverCall.get(
      `/game/admin/weekly-revenue?facilityId=${id}`
    );
    return response;
  } catch (error) {
    console.log("GetWalletListData Api error", error);
  }
};

//Add wallet
const GetWalletAddMoney = async (userId,data) => {
  try {
      const response = await serverCall.post(`/admin/add-amount/wallet/${userId}`, data);
      console.log("DeleteCityData response", response);
      return response;
  } catch (error) {
      console.log("DeleteCityData Api error", error);
  }
};


//UpdateWallet

const UpdateWallet = async (id, name) => {
    try {
        const response = await serverCall.post(`/admin/update-amount/wallet/${id}`,  name );
        console.log("UpdateCityData response", response);
        return response;
    } catch (error) {
        console.log("UpdateCityData Api error", error);
    }
};

// //DeleteUserListRequest
// const DeleteUserListRequest = async (id) => {
//     try {
//         const response = await serverCall.delete(`/admin/user/delete/${id}`);
//         console.log("DeleteCityData response", response);
//         return response;
//     } catch (error) {
//         console.log("DeleteCityData Api error", error);
//     }
// };

// //getHostListData
// const getHostListData = async (search) => {
//     try {
//         const response = await serverCall.get(`/admin/host/get-all?search=${search}`);
//         console.log("get UserListData response", response);
//         return response;
//     } catch (error) {
//         console.log("get UserListData error", error);
//     }
// };

// //getAdminListData
// const getAdminListData = async (search) => {
//     try {
//         const response = await serverCall.get(`/admin/get-all-admin?search=${search}`);
//         console.log("get UserListData response", response);
//         return response;
//     } catch (error) {
//         console.log("get UserListData error", error);
//     }
// };

//GetUpdateWalletSingleId
const GetUpdateWalletSingleId = async (id) => {
    try {
        const response = await serverCall.get(`/admin/user/${id}`);
        console.log("UserTransactionList SingleId response", response);
        return response;
    } catch (error) {
        console.log("UserTransactionList SingleId Api error", error);
    }
};

const WalletData = {
  GetWalletListData,
  UpdateWallet,
  GetUpdateWalletSingleId,
  GetWalletAddMoney
};

export default WalletData;
