
import React, { useState, useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import PitchNameEditModal from './PitchNameEditModal';
import DeletePitchNameModal from './DeletePitchNameModal';
import PitchNameService from '../../../../../services/pitchName.service';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // Import Yup for validation
import { enqueueSnackbar } from 'notistack';


const PitchName = () => {
  const pageNumner = localStorage.getItem("pitchNamepage")
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [showPitchNameModal, setShowPitchNameModal] = useState(false);
  const [showDeletePitchModal, setShowDeletePitchModal] = useState(false);
  const [selectedPitch, setSelectedPitch] = useState(null);
  const [scroll, setScroll] = useState(true);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [pageNumber, setPageNumber] = useState(pageNumner !== null ? pageNumner : 1);
  const [searchQuery, setSearchQuery] = useState('');


  const getPitchNameData = async () => {
    try {
      setLoading(true);
      const response = await PitchNameService.getPitchName();
      console.log("PitchName GetAll Page", response);
      setData(response.data["data"]);

      setLoading(false);
      setLoadingData(false);
    //   enqueueSnackbar(response.data.message, {
    //     variant: "success", autoHideDuration: 1000, anchorOrigin: {
    //         vertical: "bottom", horizontal: "right"
    //     }
    // })
    } catch (error) {
      console.log("GetAll PitchName errrrrr", error);
      setLoading(false);
      setLoadingData(false);
    }
  };
  useEffect(() => {
    getPitchNameData();
  }, []);

  useEffect(() => {
    if (scroll) {
      window.scrollTo(0, 0);
    }
  }, [scroll]);

  const handleClosePitchNameModal = () => {
    setShowPitchNameModal(false);
    setSelectedPitch(null);
  };


  const handleFormSubmit = async (values, action) => {
    console.log("handleFormSubmit", values);
    if (!values._id) {
      try {
        const response = await PitchNameService.CreatePitchName(values.name);
        action.resetForm();
        getPitchNameData();
        setShowPitchNameModal(false);
        enqueueSnackbar(response.data.message, {
          variant: "success", autoHideDuration: 4000, anchorOrigin: {
            vertical: "bottom", horizontal: "right"
          }
        })
        action.resetForm();
        console.log("response", response);
      } catch (e) {
        console.log("e", e);
        enqueueSnackbar(e.response.data.message, {
          variant: "error", autoHideDuration: 4000, anchorOrigin: {
            vertical: "bottom", horizontal: "right"
          }
        });
      }
    } else {
      try {
        const response = await PitchNameService.UpdatePitchNameRequest(values._id, values.name);
        action.resetForm();
        getPitchNameData();
        setShowPitchNameModal(false);
        enqueueSnackbar(response.data.message, {
          variant: "success", autoHideDuration: 4000, anchorOrigin: {
            vertical: "bottom", horizontal: "right"
          }

        })
        console.log("Update", response.data);
      } catch (e) {
        enqueueSnackbar(e.response.data.message, {
          variant: "error", autoHideDuration: 4000, anchorOrigin: {
            vertical: "bottom", horizontal: "right"
          }
        });
        console.log("e", e);
      }
    }
  }


  const handleEditPitch = async (id) => {
    setSelectedPitch(id);
    setShowPitchNameModal(true);
    if (id) {
      console.log("id", id);
      const response = await PitchNameService.GetPitchNameSingleId(id);
      console.log("response..", response);
      const result = response.data.data
      console.log("update", result);
      formik.setFieldValue("name", result.name);
      formik.setFieldValue("_id", result._id);
    } else {
      formik.setFieldValue("name", "");
      formik.resetForm()
    }
  };

  const handleDeletePitch = (_id, name) => {
    setDeletingIndex(_id);
    setShowDeletePitchModal(true);
    setSelectedPitch({ id: _id, name: name });
  };

  const handleConfirmDelete = async (id) => {
    try {
      const response = await PitchNameService.DeletePitchNameRequest(id);
      console.log("Confirm Delete", response);
      setShowDeletePitchModal(false);
      getPitchNameData();

      enqueueSnackbar(response.data.message, {
        variant: "error", autoHideDuration: 4000, anchorOrigin: {
          vertical: "bottom", horizontal: "right"
        }
      });
    } catch (e) {
      console.log(e.response.data.message);
      enqueueSnackbar("Error deleting city", {
        variant: "error", autoHideDuration: 4000, anchorOrigin: {
          vertical: "bottom", horizontal: "right"
        }
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Pitch name is required'),
    }),
    onSubmit: handleFormSubmit
  });

  const columns = [
    {
      name: 'ID',
      selector: (row) => row._id,
      sortable: true,
    },
    {
      name: 'Created Date',
      selector: (row) => {
        const date = new Date(row.createdAt);
        return date.toLocaleDateString('en-GB');
      },
      sortable: true,
    },
    {
      name: 'Pitch Name',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Update Date',
      selector: (row) => {
        const date = new Date(row.updatedAt);
        return date.toLocaleDateString('en-GB');
      },
      sortable: true,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '15px' }}>
          <Button size='sm' variant="info" onClick={() => handleEditPitch(row._id)}>
            <i className="fe fe-edit"></i>
          </Button>
          <Button size='sm' variant="danger" onClick={() => handleDeletePitch(row._id,row.name)}>
            <i className='fa fa-trash'></i>
          </Button>
        </div>
      ),
    },
  ];

  //TEXT SEARCH CHNAGES
  const filteredData = searchQuery
  ? data.filter((item) => {
      return (
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item._id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (item.players && item.players.toString().toLowerCase().includes(searchQuery.toLowerCase())) ||
        (item.minimumPlayers && item.minimumPlayers.toString().toLowerCase().includes(searchQuery.toLowerCase())) ||
        new Date(item.updatedAt).toLocaleDateString('en-GB').toLowerCase().includes(searchQuery.toLowerCase())
      );
    })
  : data;


  return (
    <>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Pitch Names</h2>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">
            <Button
              variant="primary"
              style={{ borderRadius: "5px"}}
              type="button"
              className="my-2 btn-icon-text"
              onClick={() => setShowPitchNameModal(true)}
            >
              <i className="fe fe-plus me-2"></i>Create Pitch Name
            </Button>
          </div>
        </div>
      </div>
      
      <div className="row">
        <div className="col-lg-12 mb-4">
          <Card>
            <Card.Body>
            <div className="d-flex mb-3" style={{float:"right"}}>
                <input
                  type="text"
                  placeholder="Search Pitch Name..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="form-control"
                  style={{ marginRight: "10px", width: "100%" }}
                />
                <Button
                  size="sm"
                  variant="primary"
                  onClick={() => setSearchQuery('')}
                  style={{ borderRadius: "5px" }}
                >
                  Clear
                </Button>
              </div>
              <DataTable
                columns={columns}
                data={filteredData.reverse()}
                pagination
                paginationPerPage={10}
                progressPending={loading}
              />
            </Card.Body>

          </Card>
        </div>
      </div>
      <PitchNameEditModal
        show={showPitchNameModal}
        handleClose={handleClosePitchNameModal}
        handleSave={formik.handleSubmit} // Pass the handleSave function
        isEdit={selectedPitch !== null} // Check if there's a selected pitch for editing
        facilitiseSelect={selectedPitch} // Pass the selected pitch if it's for editing
        pitchName={selectedPitch ? selectedPitch.name : ''} // Pass the name of the selected pitch
        formik={formik}
        formikErrors={formik.errors}
      />


      <DeletePitchNameModal
        show={showDeletePitchModal}
        handleClose={() => setShowDeletePitchModal(false)}
        handleConfirmDelete={handleConfirmDelete}
        _id={deletingIndex}
        pitchName={selectedPitch ? selectedPitch.name : ""}
      />
    </>
  );
};

export default PitchName;
