import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const BankAccountEditModal = (props) => {

    const { show, handleClose, handleSave, isEdit, selectedBankData, formik, formikErrors } = props

    console.log("BankAccountEditModal",show);

    useEffect(() => {
        if (isEdit && selectedBankData) {
          formik.setValues({
            accountNumber: isEdit ? selectedBankData?.accountNumber : "",
            accountName: isEdit ? selectedBankData?.accountName : "",
            type: isEdit ? selectedBankData?.type : "",
            bankName: isEdit ? selectedBankData?.bankName : "",
          });
        }
        formik.resetForm()
      }, [show,isEdit, selectedBankData]);
    


    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header style={{ marginBottom: "0px" }} closeButton backdrop="static">
                <Modal.Title>{isEdit ? 'Update Bank Account' : 'Create Bank Account'}</Modal.Title>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>

                    <Form.Group controlId="accountNumber">
                        <Form.Label>Account Number</Form.Label>
                        <Form.Control
                            type="number"
                            name="accountNumber"
                            value={formik.values.accountNumber}
                            // onChange={formik.handleChange}
                            placeholder="Enter account number"
                            onFocus={(e) => {
                                if (e.target.value === '0') {
                                    e.target.value = '';
                                    formik.setFieldValue('accountNumber', '');
                                }
                            }}
                            onBlur={(e) => {
                                if (e.target.value === '') {
                                    e.target.value = '0';
                                    formik.setFieldValue('accountNumber', 0);
                                }
                            }}
                            onChange={(e) => {
                                const value = e.target.value;
                                if (value >= 0) {
                                    formik.setFieldValue('accountNumber', Number(value));
                                }
                            }}
                        />
                        {!isEdit && formik.touched.accountNumber && formikErrors.accountNumber && (
                            <div className="error-message text-danger">{formikErrors.accountNumber}</div>
                        )}
                    </Form.Group>
                    <Form.Group controlId="accountName" style={{ marginTop: "10px" }}>
                        <Form.Label>Holder Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="accountName"
                            value={formik.values.accountName}
                            onChange={formik.handleChange}
                            placeholder="Account holder name"
                        />
                        {!isEdit && formik.touched.accountName && formikErrors.accountName && (
                            <div className="error-message text-danger">{formikErrors.accountName}</div>
                        )}
                    </Form.Group>
                    <Form.Group controlId="type" style={{ marginTop: "10px" }}>
    <Form.Label>Account Type</Form.Label>
    <Form.Control
        as="select"
        name="type"
        value={formik.values.type}
        onChange={formik.handleChange}
        placeholder="Select account type"
    >
        <option value="">Select account type</option>
        <option value="CURRENT">Current</option>
        <option value="SAVINGS">Savings</option>
        <option value="FIXED">Fixed</option>
    </Form.Control>
    {!isEdit && formik.touched.type && formikErrors.type && (
        <div className="error-message text-danger">{formikErrors.type}</div>
    )}
</Form.Group>

                    <Form.Group controlId="bankName" style={{ marginTop: "10px" }}>
                        <Form.Label>Name Of Bank</Form.Label>
                        <Form.Control
                            type="text"
                            name="bankName"
                            value={formik.values.bankName}
                            onChange={formik.handleChange}
                            placeholder="Enter bank name"
                        />
                        {!isEdit && formik.touched.bankName && formikErrors.bankName && (
                            <div className="error-message text-danger">{formikErrors.bankName}</div>
                        )}
                    </Form.Group>

                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" type='submit'>
                        {isEdit ? 'Update Bank Account' : 'Create Bank Account'}
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};

export default BankAccountEditModal;
