import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import ProfileEditModal from './ProfileEditModal'

const Profile = () => {

    const [show, setShow] = useState(false);

    const handleCreateClick = () => {
        setShow(true)
    }

    return (<>

        <div className="page-header">
            <div>
                <h2 className="main-content-title tx-24 mg-b-5">Player Profile</h2>
            </div>
            <div className="d-flex">
                <div className="justify-content-center">
                    <Button
                        variant="primary"
                        type="button"
                        className="my-2 btn-icon-text"
                        onClick={handleCreateClick}
                    >
                        <i className="fe fe-plus me-2"></i>Create Profile 
                    </Button>
                </div>
            </div>
        </div>
<ProfileEditModal
show={show}

/>
    </>)
}

export default Profile