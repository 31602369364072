import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import "../../../../../assets/css/style.css";
import Map from "./Map";

const CreateFacilitiseModal = (props) => {
  const {
    show,
    handleClose,
    handleSave,
    isEdit,
    facilitiseSelect,
    formik,
    handleFileChange,
    setIsEditing,
    data,
    handleNameChange,
    setIsNewImage,
  } = props;
  const [selectedLocation, setSelectedLocation] = useState({
    latitude: "",
    longitude: "",
  });

  useEffect(() => {
    formik.setFieldValue("coordinates", [
      selectedLocation.latitude,
      selectedLocation.longitude,
    ]);
  }, [selectedLocation]);
  const [address, setAddress] = useState("");


  useEffect(() => {
    if (isEdit && facilitiseSelect) {
      formik.setValues({
        name: isEdit ? facilitiseSelect?.name : "",
        file: isEdit ? facilitiseSelect?.file : "",
        coordinates: isEdit ? facilitiseSelect?.coordinates : "",
        firstName: isEdit ? facilitiseSelect?.firstName : "",
        lastName: isEdit ? facilitiseSelect?.lastName : "",
      });
    }
  }, [isEdit, facilitiseSelect, show]);


  return (
    <Modal
      {...props}
      show={show}
      onHide={handleClose}
      centered
      backdrop="static"
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header
          closeButton
          onClick={() => {
            handleClose();
            setIsEditing(false);
            formik.resetForm();
          }}
        >
          <Modal.Title>
            {isEdit ? "Edit Facility" : "Create Facility"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="name">
            <Form.Label>Facility Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Facility Name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              isInvalid={formik.touched.name && !!formik.errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {formik.touched.name && formik.errors.name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-2" controlId="facilityOwner">
            <Form.Label>Facility Owner</Form.Label>
            <Form.Control
              as="select"
              name="facilityOwner"
              className="form-select"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.facilityOwner}
              style={{ textAlign: "center" }}
            >
              <option value="">Select Facility Owner</option>
              {data.map((setting) => {
                return (
                  <option key={setting._id} value={`${setting._id}`}>
                    {setting.firstName} {setting.lastName}
                  </option>
                );
              })}
            </Form.Control>

            <Form.Control.Feedback type="invalid">
              {formik.touched.firstName &&
                formik.touched.lastName &&
                formik.errors.firstName &&
                formik.errors.lastName}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mt-2 dearch-dropdown" controlId="coordinates">
            <Form.Label>Facility Location </Form.Label>
            <Map
              setSelectedLocation={setSelectedLocation}
              setAddress={setAddress}
              formik={formik}
            />
          </Form.Group>

          <Form.Group style={{ marginTop: "10px" }} controlId="file">
            <Form.Label>
              {" "}
              {isEdit ? (
                <p>Edit Facility Image :</p>
              ) : (
                <p>Add Facility Image :</p>
              )}
            </Form.Label>
            <Form.Control
              type="file"
              name="imagelink"
              style={{ padding: "0px 10px" }}
              onChange={(event) => {
                setIsNewImage(true);
                formik.setFieldValue("file", event.currentTarget.files[0]);
              }}
              onBlur={formik.handleBlur}
              // isInvalid={formik.touched.file && !!formik.errors.file}
            />
            {isEdit && (
              <img
                style={{
                  marginTop: "2%",
                  width: "50%",
                  textAlign: "center",
                  marginLeft: "25%",
                }}
                src={`${formik.values.file}?${Date.now()}`}
                alt="Community Image"
              />
            )}
            {/* <Form.Control.Feedback type="invalid">
              {formik.touched.file && formik.errors.file}
            </Form.Control.Feedback> */}
          </Form.Group>
          <Form.Group controlId="isPublish" style={{ marginTop: "10px" }}>
            <Form.Check
              type="checkbox"
              label="Publish Facility"
              name="isPublish"
              checked={formik.values.isPublish}
              onChange={formik.handleChange}
              style={{
                fontWeight: "bolder",
                display: "flex",
                alignItems: "center",
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="primary">
            {isEdit ? "Update Facility" : "Add Facility"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CreateFacilitiseModal;
