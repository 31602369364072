import React from "react";
import { Modal, Button } from "react-bootstrap";

const GameSettingsDetailsModal = ({
  show,
  handleClose,
  selectedGameSettingForView,
}) => {
  //Individual Game Settings
  const maxRevenue =
    parseFloat(selectedGameSettingForView?.individualGame.price) *
    parseFloat(selectedGameSettingForView?.pitchTypeId.players);
  const profitMargin =
    parseFloat(selectedGameSettingForView?.individualGame.price) *
      parseFloat(selectedGameSettingForView?.pitchTypeId.minimumPlayers) -
    parseFloat(selectedGameSettingForView?.individualGame.costOfPitch);

  //Fullpitch Pitch Settings
  const pricePerIndividual =
    parseFloat(selectedGameSettingForView?.fullPitchBooking.price) /
    parseFloat(selectedGameSettingForView?.pitchTypeId.players);
  const profitMarginApp =
    parseFloat(pricePerIndividual) *
      parseFloat(selectedGameSettingForView?.pitchTypeId.minimumPlayers) -
    parseFloat(selectedGameSettingForView?.fullPitchBooking.costOfPitch);

  //Friendly Pitch Settings
  const pricePerIndividual2 =
    parseFloat(selectedGameSettingForView?.friendlyGame.price) /
    parseFloat(selectedGameSettingForView?.pitchTypeId.players);
  const profitMarginApp2 =
    parseFloat(pricePerIndividual2) *
      parseFloat(selectedGameSettingForView?.pitchTypeId.minimumPlayers) -
    parseFloat(selectedGameSettingForView?.friendlyGame.costOfPitch);

  return (
    <>
      <Modal centered show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <div style={{ marginLeft: "30%", marginBottom: "0px" }}>
            <p
              style={{
                fontSize: "20px",
                color: "black",
                fontWeight: "bold",
                marginBottom: "0px",
              }}
            >
              Game Setting Details
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div
            className="d-flex"
            style={{
              justifyContent: "space-around",
              textAlign: "center",
              marginBottom: "-17px",
            }}
          >
            <p>
              <strong>Pitch Type </strong>{" "}
              <p style={{ color: "green", fontWeight: "bold" }}>
                {selectedGameSettingForView?.pitchTypeId.name}
              </p>
            </p>
            <p>
              <strong>Max Players </strong>{" "}
              <p style={{ color: "green", fontWeight: "bold" }}>
                {selectedGameSettingForView?.pitchTypeId.players}
              </p>
            </p>
            <p>
              <strong>Min Players </strong>{" "}
              <p style={{ color: "green", fontWeight: "bold" }}>
                {selectedGameSettingForView?.pitchTypeId.minimumPlayers}
              </p>
            </p>
          </div>
          <div
            className=""
            style={{
              justifyContent: "center",
              textAlign: "center",
              gap: "0px",
              width: "auto",
              display: "ruby-text",
              marginBottom: "10px",
            }}
          >
            <p style={{ marginBottom: "0px" }}>
              <strong>Pitch Names : </strong>
            </p>
            {selectedGameSettingForView?.pitch.map((pitch, index) => (
              <p
                key={index}
                style={{
                  color: "green",
                  fontWeight: "bold",
                  display: "auto",
                  margin: "1%",
                }}
              >
                {index === selectedGameSettingForView.pitch.length - 1
                  ? pitch.name
                  : `${pitch.name},`}
              </p>
            ))}
          </div>

          <hr
            style={{
              width: "420px",
              height: "1px",
              borderLeft: "1px solid #ccc",
              margin: "0 10px",
              marginBottom: "10px",
            }}
          />

          <h4 style={{ textAlign: "center", color: "black" }}>
            Individual Game
          </h4>

          <div
            className="gap-4"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "5%",
            }}
          >
            <div style={{ fontSize: "15px", textAlign: "center" }}>
              <p>
                <strong>Cost of Pitch </strong>{" "}
                <p style={{ color: "green", fontWeight: "bold" }}>
                  {selectedGameSettingForView?.individualGame.costOfPitch.toFixed(
                    2
                  )}{" "}
                  €
                </p>
              </p>
              <p>
                <strong>Host Commission </strong>{" "}
                <p style={{ color: "green", fontWeight: "bold" }}>
                  {selectedGameSettingForView?.individualGame.hostCommission.toFixed(
                    2
                  )}
                  %
                </p>
              </p>
              <p>
                <strong>Max Revenue </strong>{" "}
                <p style={{ color: "green", fontWeight: "bold" }}>
                  {maxRevenue.toFixed(2)} €
                </p>
              </p>
            </div>
            <hr
              style={{
                width: "1px",
                height: "150px",
                borderLeft: "1px solid #ccc",
                margin: "0 10px",
              }}
            />

            <div style={{ fontSize: "15px", textAlign: "center" }}>
              <p>
                <strong>Game Price </strong>{" "}
                <p style={{ color: "green", fontWeight: "bold" }}>
                  {selectedGameSettingForView?.individualGame.price.toFixed(2)}{" "}
                  €
                </p>
              </p>
              <p>
                <strong>Target Margin </strong>{" "}
                <p style={{ color: "green", fontWeight: "bold" }}>
                  {selectedGameSettingForView?.individualGame.targetMargin.toFixed(
                    2
                  )}
                  %
                </p>
              </p>
              <p>
                <strong>Profit Margin(app) </strong>{" "}
                <p style={{ color: "green", fontWeight: "bold" }}>
                  {profitMargin.toFixed(2)} €
                </p>
              </p>
            </div>

            {/* <div style={{ fontSize: "15px", textAlign: "center" }}>
                        {(selectedGameSettingForView?.gameType === "Individual Games") && (
                            <>
                                <p><strong >Game Setting Name : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.gamesettingname}</p></p>
                                <p><strong>Pitch Type : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.pitchType}</p></p>
                                <p><strong>Game Type : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.gameType}</p></p>
                                <p><strong>Number of Players : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.numberofplayersindividual}</p></p>
                                <p><strong>Threshold Number : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.thresholdindividual}</p> </p>
                                <p><strong>Commission : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.hostCommision}%</p></p>

                            </>
                        )}
                        {(selectedGameSettingForView?.gameType !== "Individual Games") && (
                            <>
                                <p><strong>Game Setting Name : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.name}</p></p>
                                <p><strong>Pitch Type : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.pitchType}</p></p>
                                <p><strong>Game Type : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.gameType}</p></p>
                                <p><strong>Price : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.amount}</p></p>

                            </>
                        )}
                    </div>


                    <hr style={{ width: '1px', height: '200px', borderLeft: '1px solid #ccc', margin: '0 10px' }} />


                    <div style={{ fontSize: "15px", textAlign: "center" }}>
                        {(selectedGameSettingForView?.gameType === 'Individual Games') && (
                            <>
                                <p><strong>Actual Revenue(App) : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.actualrevenueindividual} $</p></p>
                                <p><strong>Target Margin : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.targetmarginindividual}%</p></p>
                                <p><strong>Max Revenue : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.maxrevenueindividual} $</p></p>
                                <p><strong>Cost of Pitch : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.costoffullpitch}</p></p>
                            </>
                        )}
                        {selectedGameSettingForView?.gameType !== "Individual Games" && (
                            <>
                                <p><strong>Profit Margin(APP) : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.appMargin}</p></p>
                                <p><strong>Price Per Games : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.pricePerFullPitch} $</p></p>
                                <p><strong>Price Per Individual : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.individualAmount} $</p></p>
                            </>
                        )}
                    </div> */}
          </div>

          <h5 style={{ textAlign: "center", color: "#00ADEF" }}>
            Full Pitch Booking
          </h5>

          <div
            className="gap-4"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "5%",
            }}
          >
            <div style={{ fontSize: "15px", textAlign: "center" }}>
              <p>
                <strong>Cost of Pitch </strong>{" "}
                <p style={{ color: "green", fontWeight: "bold" }}>
                  {selectedGameSettingForView?.fullPitchBooking.costOfPitch.toFixed(
                    2
                  )}{" "}
                  €
                </p>
              </p>
              <p>
                <strong>Game Price </strong>{" "}
                <p style={{ color: "green", fontWeight: "bold" }}>
                  {selectedGameSettingForView?.fullPitchBooking.price.toFixed(
                    2
                  )}{" "}
                  €
                </p>
              </p>
              <p>
                <strong>Price Per Individual : </strong>{" "}
                <p style={{ color: "green", fontWeight: "bold" }}>
                  {pricePerIndividual.toFixed(2)} €
                </p>
              </p>
            </div>
            <hr
              style={{
                width: "1px",
                height: "90px",
                borderLeft: "1px solid #ccc",
                margin: "0 10px",
              }}
            />

            <div style={{ fontSize: "15px", textAlign: "center" }}>
              <p>
                <strong>Profit Margin(full pitch) </strong>{" "}
                <p style={{ color: "green", fontWeight: "bold" }}>
                  {selectedGameSettingForView?.fullPitchBooking.profitMargin.toFixed(
                    2
                  )}
                  %
                </p>
              </p>
              <p>
                <strong>Profit Margin(app) </strong>{" "}
                <p style={{ color: "green", fontWeight: "bold" }}>
                  {profitMarginApp.toFixed(2)} €
                </p>
              </p>
            </div>

            {/* <div style={{ fontSize: "15px", textAlign: "center" }}>
        {(selectedGameSettingForView?.gameType === "Individual Games") && (
            <>
                <p><strong >Game Setting Name : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.gamesettingname}</p></p>
                <p><strong>Pitch Type : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.pitchType}</p></p>
                <p><strong>Game Type : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.gameType}</p></p>
                <p><strong>Number of Players : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.numberofplayersindividual}</p></p>
                <p><strong>Threshold Number : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.thresholdindividual}</p> </p>
                <p><strong>Commission : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.hostCommision}%</p></p>

            </>
        )}
        {(selectedGameSettingForView?.gameType !== "Individual Games") && (
            <>
                <p><strong>Game Setting Name : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.name}</p></p>
                <p><strong>Pitch Type : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.pitchType}</p></p>
                <p><strong>Game Type : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.gameType}</p></p>
                <p><strong>Price : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.amount}</p></p>

            </>
        )}
    </div>


    <hr style={{ width: '1px', height: '200px', borderLeft: '1px solid #ccc', margin: '0 10px' }} />


    <div style={{ fontSize: "15px", textAlign: "center" }}>
        {(selectedGameSettingForView?.gameType === 'Individual Games') && (
            <>
                <p><strong>Actual Revenue(App) : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.actualrevenueindividual} $</p></p>
                <p><strong>Target Margin : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.targetmarginindividual}%</p></p>
                <p><strong>Max Revenue : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.maxrevenueindividual} $</p></p>
                <p><strong>Cost of Pitch : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.costoffullpitch}</p></p>
            </>
        )}
        {selectedGameSettingForView?.gameType !== "Individual Games" && (
            <>
                <p><strong>Profit Margin(APP) : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.appMargin}</p></p>
                <p><strong>Price Per Games : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.pricePerFullPitch} $</p></p>
                <p><strong>Price Per Individual : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.individualAmount} $</p></p>
            </>
        )}
    </div> */}
          </div>

          <h5 style={{ textAlign: "center", color: "#C4273B" }}>
            Friendly Games
          </h5>

          <div
            className="gap-4"
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "5%",
            }}
          >
            <div style={{ fontSize: "15px", textAlign: "center" }}>
              <p>
                <strong>Cost of Pitch </strong>{" "}
                <p style={{ color: "green", fontWeight: "bold" }}>
                  {selectedGameSettingForView?.friendlyGame.costOfPitch.toFixed(
                    2
                  )}{" "}
                  €
                </p>
              </p>
              <p>
                <strong>Game Price </strong>{" "}
                <p style={{ color: "green", fontWeight: "bold" }}>
                  {selectedGameSettingForView?.friendlyGame.price.toFixed(2)} €
                </p>
              </p>
              <p>
                <strong>Price Per Individual : </strong>{" "}
                <p style={{ color: "green", fontWeight: "bold" }}>
                  {pricePerIndividual2.toFixed(2)} €
                </p>
              </p>
            </div>
            <hr
              style={{
                width: "1px",
                height: "90px",
                borderLeft: "1px solid #ccc",
                margin: "0 10px",
              }}
            />

            <div style={{ fontSize: "15px", textAlign: "center" }}>
              <p>
                <strong>Profit Margin(friendly) </strong>{" "}
                <p style={{ color: "green", fontWeight: "bold" }}>
                  {selectedGameSettingForView?.friendlyGame.profitMargin.toFixed(
                    2
                  )}
                  %
                </p>
              </p>
              <p>
                <strong>Profit Margin(app) </strong>{" "}
                <p style={{ color: "green", fontWeight: "bold" }}>
                  {profitMarginApp2.toFixed(2)} €
                </p>
              </p>
              {/* <p><strong>Max Revenue : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.maxrevenueindividual} $</p></p> */}
            </div>

            {/* <div style={{ fontSize: "15px", textAlign: "center" }}>
        {(selectedGameSettingForView?.gameType === "Individual Games") && (
            <>
                <p><strong >Game Setting Name : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.gamesettingname}</p></p>
                <p><strong>Pitch Type : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.pitchType}</p></p>
                <p><strong>Game Type : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.gameType}</p></p>
                <p><strong>Number of Players : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.numberofplayersindividual}</p></p>
                <p><strong>Threshold Number : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.thresholdindividual}</p> </p>
                <p><strong>Commission : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.hostCommision}%</p></p>

            </>
        )}
        {(selectedGameSettingForView?.gameType !== "Individual Games") && (
            <>
                <p><strong>Game Setting Name : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.name}</p></p>
                <p><strong>Pitch Type : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.pitchType}</p></p>
                <p><strong>Game Type : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.gameType}</p></p>
                <p><strong>Price : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.amount}</p></p>

            </>
        )}
    </div>


    <hr style={{ width: '1px', height: '200px', borderLeft: '1px solid #ccc', margin: '0 10px' }} />


    <div style={{ fontSize: "15px", textAlign: "center" }}>
        {(selectedGameSettingForView?.gameType === 'Individual Games') && (
            <>
                <p><strong>Actual Revenue(App) : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.actualrevenueindividual} $</p></p>
                <p><strong>Target Margin : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.targetmarginindividual}%</p></p>
                <p><strong>Max Revenue : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.maxrevenueindividual} $</p></p>
                <p><strong>Cost of Pitch : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.costoffullpitch}</p></p>
            </>
        )}
        {selectedGameSettingForView?.gameType !== "Individual Games" && (
            <>
                <p><strong>Profit Margin(APP) : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.appMargin}</p></p>
                <p><strong>Price Per Games : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.pricePerFullPitch} $</p></p>
                <p><strong>Price Per Individual : </strong> <p style={{ color: "green", fontWeight: "bold" }}>{selectedGameSettingForView?.individualAmount} $</p></p>
            </>
        )}
    </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleClose(true)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GameSettingsDetailsModal;
