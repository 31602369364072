import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';


const CommunityEditModal = (props) => {
  const { showModal, handleClose, isEditMode,formik,selectedCommunity,loading,setIsEditMode } = props

  useEffect(() => {
    if (isEditMode && selectedCommunity) {
      formik.setValues({
        title: isEditMode ? selectedCommunity?.title : "",
        description: isEditMode ? selectedCommunity?.description : "",
        file: isEditMode ? selectedCommunity?.file : "",
       
      });
    }
    // formik.resetForm()
  }, [showModal, selectedCommunity]);


  return (
    <Modal show={showModal} onHide={handleClose} centered>
         <form onSubmit={formik.handleSubmit}>
         <Modal.Header closeButton
          onClick={() => {
            handleClose();
              setIsEditMode(false);
            formik.resetForm();
          }}>
        <Modal.Title>{isEditMode ? 'Update Community' : 'Create Community'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
   
          <Form.Group controlId="title">
            <Form.Label> {isEditMode ? (
                <b>Edit Community Title :</b>
              ) : (
                <b>Add Community Title :</b>
              )}</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter Community name"
              isInvalid={formik.touched.title && !!formik.errors.title}
            />
         <Form.Control.Feedback type="invalid">
              {formik.touched.title && formik.errors.title}
            </Form.Control.Feedback>
          </Form.Group>


          <Form.Group style={{marginTop:"10px"}} controlId="description">
            <Form.Label> {isEditMode ? (
                <b>Edit Community Description :</b>
              ) : (
                <b>Add Community Description :</b>
              )}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter Community detail"
              isInvalid={formik.touched.description && !!formik.errors.description}
            />
            <Form.Control.Feedback type="invalid">
              {formik.touched.description && formik.errors.description}
            </Form.Control.Feedback>
          </Form.Group>
         
            <Form.Group style={{marginTop:"10px"}} controlId="file">
              <Form.Label> {isEditMode ? (
                <b>Edit Community Image :</b>
              ) : (
                <b>Add Community Image :</b>
              )}</Form.Label>
              <Form.Control
                type="file"
                name="image"
                onChange={(event) => {
                  formik.setFieldValue('file', event.currentTarget.files[0]);
                }}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.file && !!formik.errors.file}
              />
              {isEditMode && <img style={{marginTop:"2%",width:"50%",textAlign:"center",marginLeft:"25%"}} src={formik.values.file} alt="Community Image"/>}
              <Form.Control.Feedback type="invalid">
              {formik.touched.file && formik.errors.file}
            </Form.Control.Feedback>
            </Form.Group>
      
          {/* Add other form fields for city data as needed */}
       
      </Modal.Body>
      <Modal.Footer> 
        <Button variant="primary" type="submit" disabled={!formik.isValid || loading}>
          {isEditMode ? 'Update Community' : 'Add Community'}
        </Button>
      </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CommunityEditModal;
