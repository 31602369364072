import React, { useState, useEffect } from 'react';
import { Button, Card, Dropdown, Form, Table, Modal } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import DatePicker, { Calendar } from 'react-multi-date-picker';
import GameData from '../../../../../services/game.service';
import FacilityData from '../../../../../services/facility.service';
import Select from 'react-select';

const localData = JSON.parse(localStorage.getItem("userProfile"));
const facilityID = localData?.facility_id;
const name = localData?.facilityName
const Role = localData?.role;



const Game = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const findDate = localStorage.getItem("date")
  const [showModal, setShowModal] = useState(false);
  const [selectedGameId, setSelectedGameId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [game, setGame] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const date = new Date();
  date.setHours(0, 0)
  date.setMinutes(0, 0)
  date.setSeconds(0, 0)
  const miliDate = date.getTime();
  // const formatedDate = moment(findDate !== null ? findDate : miliDate)
  //   .add("hours", 5)
  //   .add("minutes", 30)
  //   .valueOf();
  const formatedDate = moment(miliDate).startOf('day').valueOf();

  const [changeDate, setChangeDate] = useState(findDate !== null ? findDate : formatedDate);
  const [gameData, setGameData] = useState([]);
  const [uniquePitch, setUniquePitch] = useState([])
  const [gameSettings, setGameSettings] = useState([])


  const getFacilitySingleData = async () => {
    try {

      const response = await FacilityData.GetFacilityGameSingleId(facilityID, changeDate, "1714588199000");
      setGameData(response.data["data"]);
      console.log("checckkdk", response);
      setUniquePitch(response.data["data"].map((type) => type.timeSlots.map((time) => time)))
      console.log("CustomIds", gameData.map(item => item.pitchTypeId));

      console.log("checckkdk", id, changeDate);
    } catch (error) {
      console.error("Error fetching data", error);
      setLoading(false);
    }
  };
  console.log("responseFacilitySingleData", gameData.map(item => item.pitchTypeId));

  useEffect(() => {
    getFacilitySingleData();
    localStorage.setItem("date", changeDate);
  }, [changeDate]);

  useEffect(() => {
    const time = localStorage.getItem("date");

    const date = new Date(Number(time));
    setSelectedDate(date)
  }, [])


  useEffect(() => {
    if (facilityID) {
      getFacilitySingleData();
    }
  }, [changeDate]);



  const GameSettingName = state && state.GameSettingName;
  const GameId = state && state.GameId ? state.GameId : null;


  const navigate = useNavigate();



  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem('gameData'));
    if (savedData) {
      setGameData(savedData.gameData || {});
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('gameData', JSON.stringify({ gameData }));
  }, [gameData]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSave = (newGameData, gameId) => {

    const updatedGameData = { ...gameData };
    if (!updatedGameData[id]) {
      updatedGameData[id] = [];
    }
    const existingGameIndex = updatedGameData[id].findIndex(game => game.gameId === gameId);
    if (existingGameIndex !== -1) {
      updatedGameData[id][existingGameIndex] = {
        ...updatedGameData[id][existingGameIndex],
        ...newGameData,
      };
    } else {
      const newGameId = gameId || Date.now().toString();
      const newGame = {
        gameId: newGameId,
        ...newGameData,
      };
      updatedGameData[id].push(newGame);
    }
    setGameData(updatedGameData);
    handleCloseModal();

    const dataToSave = {
      gameData: updatedGameData,
    };
    localStorage.setItem('gameData', JSON.stringify(dataToSave));
  };

  const handleViewDetailsClick = (gameId) => {
    console.log("Savinggame", gameId);
    // if ("MASTER-ADMIN" === role) {
    //   navigate("/cities/");
    // }
    // else if ("ADMIN" === role) {
    //   navigate("/games/");
    // }
    navigate(`/games/cities/facilitise/details/game/gamedetail/${gameId}`, {
      state: {
        GameId: gameId
      },
    })
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = gameData[id] ? gameData[id].slice(indexOfFirstItem, indexOfLastItem) : [];

  // const handleNextDay = () => {
  //   const newDate = new Date(selectedDate);
  //   newDate.setDate(selectedDate.getDate() + 1);
  //   setSelectedDate(newDate);
  //   const miliDate = newDate.getTime();
  //   const formatedDate = moment(miliDate).add('hours', 5).add('minutes', 30).valueOf();
  //   setChangeDate(formatedDate);
  // };

  // const handlePreviousDay = () => {
  //   const newDate = new Date(selectedDate);
  //   newDate.setDate(selectedDate.getDate() - 1);
  //   setSelectedDate(newDate);
  //   newDate.setHours(0, 0, 0, 0);
  //   const miliDate = newDate.getTime();
  //   const formatedDate = moment(miliDate).add('hours', 5).add('minutes', 30).valueOf();
  //   setChangeDate(formatedDate);
  // };

  const handleNextDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(selectedDate.getDate() + 1);
    setSelectedDate(newDate);
    newDate.setHours(0, 0, 0, 0);
    const formatedDate = newDate.getTime();
    setChangeDate(formatedDate);
  };

  const handlePreviousDay = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(selectedDate.getDate() - 1);
    setSelectedDate(newDate);
    newDate.setHours(0, 0, 0, 0);
    const formatedDate = newDate.getTime();
    setChangeDate(formatedDate);
  };


  const handleDateChange = (date) => {
    const tempDate = new Date(date)
    const newDate = moment(tempDate).startOf('day');
    const utcDate = newDate.utc();
    const utcMilliseconds = utcDate.valueOf();
    setChangeDate(utcMilliseconds)
  };


  const getStatusColor = (status) => {
    switch (status) {
      case "ADVERTISED":
        return "green";
      case "AHEAD":
        return "rgba(0, 0, 139, 0.76)";
      case "NOT-ADVERTISED":
        return "grey";
      case "CANCELLED":
        return "red";
      case "NOT-CREATED":
        return "orange";
      case "PRE-BOOKED":
        return "#f945e4";
      default:
        return "";
    }
  };


  var headName = []

  useEffect(() => {
    const newGameData = gameData.sort((a, b) => { return a.pitchTypeName.length === 3 ? a.pitchTypeName[0] - b.pitchTypeName[0] : a.pitchTypeName.slice(0, 2) - b.pitchTypeName.slice(0, 2) })
    console.log('newGameData', gameData.sort((a, b) => a.pitchTypeName.split("v")[0] < b.pitchTypeName.split("v")[0]))
  }, [formatedDate, gameData])

  console.log("headName", headName);



  const uniqueTimeSlots = [...new Set(gameData.flatMap(data =>
    data.timeSlots.map(pitch =>
      `${moment(pitch.startTime).format('HH:mm')} - ${moment(pitch.endTime).format('HH:mm')}`
    )
  ))].sort((a, b) => moment(a.split(' - ')[0], 'HH:mm').diff(moment(b.split(' - ')[0], 'HH:mm')));

  console.log("uniqueTimeSlots", uniqueTimeSlots);


  const handleSelectChange = async (item, game) => {
    console.log("item", item, game);

    const formatStartTime = (time) => {
      return moment.utc(game.startTime).format('HH:mm')
    };
    const formatEndTime = (time) => {
      return moment.utc(game.endTime).format('HH:mm')
    };

    const startTimeFormatted = formatStartTime(new Date(item.startTime));
    const endTimeFormatted = formatEndTime(new Date(item.endTime));

    const json = {
      "pitchId": item?.pitchId,
      "pitchTypeId": item?.pitchTypeId,
      "facilityId": id,
      "date": changeDate,
      "startTime": startTimeFormatted,
      "endTime": endTimeFormatted
    };
    // console.log('itemitemitemitem', json);

    console.log("timeeeeee", startTimeFormatted, endTimeFormatted);

    const response = await GameData?.CreateGameSettings(json);
    setGameSettings(response?.data["data"])


    console.log("asdaksd", gameSettings);

  };

  const mappedSettings = gameSettings.map(setting => ({
    value: setting?._id,
    label: setting?.name
  }));
  console.log("jkfjdgg", mappedSettings);



  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
      border: 'none',
      boxShadow: 'none',
      width: '',
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: 'transparent',
      width: '',
    }),
  };


  console.log("CustomIds", id);


  return (
    <>
      {/***********************Header*************************** */}
      {/* <div className="page-header" style={{ marginBottom: "0px", marginTop: "10px" }}>
        <button size="sm" className="btn btn-primary" style={{ borderRadius: "20px" }} onClick={() => navigate(-1)}><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
      </div> */}
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "10px" }} >
        <div>
          <h2 className="main-content-title tx-24 mg-b-5 mt-2">Game</h2>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <Card>
            <Card.Header>
              <h4 style={{ color: "#615f78" }}>{name}</h4>
            </Card.Header>

            <div style={{ justifyContent: "center", marginTop: "20px", wordSpacing: "10px", display: "flex" }}>
              <div style={{ spacebetween: "50px" }}>
                <button style={{ marginRight: "10px", background: "transparent", border: "none" }} onClick={handlePreviousDay}><i className="fa fa-angle-double-left fa-lg" aria-hidden="true"></i></button>
              </div>

              <div>
                <DatePicker
                  className="text-center"
                  style={{ display: 'flex', textAlign: 'center', padding: '15px', marginTop: '-2px', fontWeight: "bolder" }}
                  selected={selectedDate}
                  value={selectedDate}
                  onChange={handleDateChange}
                  format="DD/MM/YYYY"
                />
              </div>
              <div>
                <button style={{ marginLeft: "10px", background: "transparent", border: "none" }} onClick={handleNextDay}><i className="fa fa-angle-double-right fa-lg" aria-hidden="true"></i></button>
              </div>
            </div>

            {/***********************Table Card*************************** */}
            <Card.Body>
              <div className="table-responsive">
                {gameData.length === 0 ? (
                  <p style={{ textAlign: 'center', padding: "50px" }}>"No Games available"</p>
                ) : (
                  <Table striped bordered hover style={{ borderBottom: "2px solid black" }}>
                    <thead className="custom-table-head" style={{ textAlign: 'center', fontWeight: 'bold', border: "2px solid black" }}>
                      <tr>
                        <th style={{ textAlign: 'center', fontWeight: 'bolder', border: "2px solid black", fontSize: "15px" }} className='firstheader'>Pitch Type</th>
                        {gameData.map((type, index) => {
                          const uniquePitches = {};
                          return (
                            <>
                              {
                                type.timeSlots.map((pitch, index) => (
                                  <>
                                    {pitch.games.map((game, index) => {
                                      if (!uniquePitches[game.pitchId]) {
                                        uniquePitches[game.pitchId] = true;
                                        return (
                                          <th style={{ textAlign: 'center', fontWeight: 'bolder', backgroundColor: "#00ADEF", color: "black", border: "2px solid black" }} key={index}>
                                            {type.pitchTypeName}
                                          </th>
                                        );
                                      }
                                      return null;
                                    })}
                                  </>
                                ))
                              }
                            </>
                          );
                        })}
                      </tr>
                      <tr>
                        <th style={{ textAlign: 'center', fontWeight: 'bolder', border: "2px solid black", fontSize: "15px" }} className='firstheader'>Pitch Name</th>
                        {gameData.map((data, index) => {
                          const uniquePitches = {};
                          return (
                            <>
                              {
                                data.timeSlots.map((pitch, index) => (
                                  <>
                                    {pitch.games
                                      .slice()
                                      .sort((a, b) => {
                                        const pitchNameA =
                                          a.pitchName.toUpperCase();
                                        const pitchNameB =
                                          b.pitchName.toUpperCase();

                                        if (pitchNameA < pitchNameB) {
                                          return -1;
                                        }
                                        if (pitchNameA > pitchNameB) {
                                          return 1;
                                        }
                                        return 0;
                                      })
                                      .map((game, index) => {
                                        if (!uniquePitches[game.pitchId]) {
                                          uniquePitches[game.pitchId] = true;
                                          console.log("gamegamegamegamegamegamegame", game);
                                          headName.push(
                                            { pitchId: game.pitchId, name: game?.pitchName, pitchTypeId: data?.pitchTypeId, type: data?.pitchTypeName, count: data?.maxPlayers, startTime: game?.startTime, endTime: game?.endTime }
                                          )
                                          return (
                                            <th style={{ textAlign: 'center', fontWeight: 'bolder', backgroundColor: "#00ADEF", color: "black", border: "2px solid black" }}>
                                              {game.pitchName}
                                            </th>
                                          );
                                        }
                                        return null;
                                      })}
                                  </>
                                ))
                              }
                            </>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody style={{ borderBottom: "2px solid black" }}>
                      {Array.from(uniqueTimeSlots).map((timeSlot, slotIndex) => {

                        return (
                          <tr key={slotIndex}>
                            <td style={{ textAlign: 'center', fontWeight: 'bolder', backgroundColor: "#00ADEF", color: "black", border: "2px solid black" }}>
                              {timeSlot}
                            </td>
                            {headName.map((header, headerIndex) => {
                              const game = gameData.find(type => type.pitchTypeName === header.type)?.timeSlots.flatMap(slot => slot.games).find(game =>
                                game.pitchName === header.name && moment(game.startTime).format('HH:mm') + ' - ' + moment(game.endTime).format('HH:mm') === timeSlot);

                              let headd = {}


                              const gameTime = gameData.find(type => {
                                return type.pitchTypeName
                              })?.timeSlots.flatMap(slot => slot.games).find(game =>
                                moment(game.startTime).format('HH:mm') + ' - ' + moment(game.endTime).format('HH:mm') === timeSlot);


                              // const newGameTime = gameData.find(type => {
                              //   return type.pitchTypeName
                              // })?.timeSlots.flatMap(slot => slot.games).find(game =>
                              //   moment(game.startTime).format('HH:mm') + ' - ' + moment(game.endTime).format('HH:mm') === timeSlot)

                              const newGameTime = gameData.flatMap(type =>
                                type.timeSlots.flatMap(slot =>
                                  slot.games.filter(game =>
                                    moment(game.startTime).format('HH:mm') + ' - ' + moment(game.endTime).format('HH:mm') === timeSlot
                                  )
                                )
                              )[0];

                              console.log("timeSlot:", timeSlot);
                              console.log("Formatted time:", gameData.flatMap(type => type.timeSlots.flatMap(slot => slot.games)).map(game => moment(game.startTime).format('HH:mm') + ' - ' + moment(game.endTime).format('HH:mm')));

                              console.log(newGameTime); // Debugging to check the value of newGameTime


                              headd = { ...gameTime, pitchName: newGameTime?.pitchName, pitchId: newGameTime?.pitchId }
                              console.log("gameTime", headd, gameData.find(type => {
                                return type.pitchTypeName
                              })?.timeSlots.flatMap(slot => slot.games).find(game =>
                                moment(game.startTime).format('HH:mm') + ' - ' + moment(game.endTime).format('HH:mm')));

                              if (game) {
                                return (
                                  <td key={headerIndex} onClick={() => handleViewDetailsClick(game?.gameId)} style={{ backgroundColor: getStatusColor(game.status), color: "black", fontWeight: 'bold', border: "2px solid black", textAlign: "center",cursor: "pointer" }}>
                                    {game.playerCount}/{header.count}
                                  </td>
                                );
                              } else {
                                return Role === "MASTER-ADMIN" ? (
                                  <td
                                    onClick={() => handleSelectChange(header, headd)}
                                    key={headerIndex}
                                    style={{
                                      backgroundColor: '#e28700',
                                      fontWeight: 'bold',
                                      border: "2px solid black",
                                      textAlign: "center",
                                      
                                    }}
                                  >
                                    <Select
                                      styles={customStyles}
                                      options={mappedSettings}
                                      onChange={(selectedOption) => handleSelectChange(header, headd)}
                                      placeholder="-"
                                    />
                                  </td>
                                ) : (
                                  <td
                                    onClick={() => handleSelectChange(header, headd)}
                                    key={headerIndex}
                                    style={{
                                      backgroundColor: '#e28700',
                                      color: "black",
                                      fontWeight: 'bold',
                                      border: "2px solid black",
                                      textAlign: "center",
                                     
                                    }}
                                  >
                                    -
                                  </td>
                                );
                              }

                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              </div>
            </Card.Body>



            {/***********************Table Card*************************** */}

          </Card>


          <Modal centered show={showDetailsModal} onHide={() => setShowDetailsModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Game Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Game Settings </p>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowDetailsModal(false)}>Close</Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div >

      {/***********************Pagination*************************** */}
      <div className="pagination">
        <ul className="pagination">
          {gameData[id] && gameData[id].length > 0 &&
            Array.from({ length: Math.ceil(gameData[id].length / itemsPerPage) }, (_, i) => (
              <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
                <button onClick={() => setCurrentPage(i + 1)} className="page-link">
                  {i + 1}
                </button>
              </li>
            ))}
        </ul>
      </div>

    </>
  );
};

export default Game;