import serverCall from "../serverCall";

//getPitchType
const getPitchType = async () => {
    try {
      const response = await serverCall.get(`/pitch-type/admin/getall`);
      console.log("getPitchType response", response);
      return response;
    } catch (error) {
      console.log("getPitchType error", error);
    }
  };

//CreatePitchType
const CreatePitchType = async (name) => {
  const response = await serverCall.post(
    `/pitch-type/admin/create`, name 
  );
  return response;
};

//GetPitchTypeSingleId
const GetPitchTypeSingleId = async (id) => {
  try {
      const response = await serverCall.get(`/pitch-type/admin/get/${id}`);
      console.log("City SingleId response", response);
      return response;
  } catch (error) {
      console.log("getCitySingleId Api error", error);
  }
};

//DeletePitchTypeRequest
const DeletePitchTypeRequest = async (id) => {
  try {
    const response = await serverCall.delete(`/pitch-type/admin/delete/${id}`);
    console.log("DeletePitchType response", response);
    return response;
} catch (error) {
    console.log("DeletePitchType Api error", error);
}
};

//UpdatePitchTypeRequest
const UpdatePitchTypeRequest = async (id, name) => {
  try {
      const response = await serverCall.put(`/pitch-type/admin/update/${id}`, name );
      console.log("UpdateCityData response", response);
      return response;
  } catch (error) {
      console.log("UpdateCityData Api error", error);
  }
};




const PitchTypeService = {
    getPitchType,
    CreatePitchType,
    GetPitchTypeSingleId,
    DeletePitchTypeRequest,
    UpdatePitchTypeRequest
};

export default PitchTypeService;
