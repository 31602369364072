import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";

const AddFaqQuestion = (props) => {
  const { showModal, handleClose, isEditMode, selectedFaq, formik, loading } = props

 
  useEffect(() => {
    if (isEditMode && selectedFaq) {
      formik.setValues({
        question: isEditMode ? selectedFaq?.question : "",
        answer: isEditMode ? selectedFaq?.answer : ""
      });
    }
    // formik.resetForm()
  }, [showModal, selectedFaq, showModal]);

  return (
    <Modal centered show={showModal} onHide={handleClose}>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton
          onClick={() => {
            handleClose();
            formik.resetForm();
          }}>
          <Modal.Title>
            {isEditMode ? "Edit FAQ " : "Create FAQ "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="question" className="mg-t-20" >
            <Form.Label style={{ color: "black", marginTop: "15px", fontWeight: "lighter" }}>
              {isEditMode ? (
                <b>Edit Question :</b>
              ) : (
                <b>Add Question :</b>
              )}
            </Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              rows={2}
              placeholder="Enter Question.."
              name="question"
              value={formik.values.question}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.question && !!formik.errors.question}
            />
            <Form.Control.Feedback type="invalid">
              {formik.touched.question && formik.errors.question}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group  controlId="answer" className="poGroupsition-relative mb-3 mt-2">
            <Form.Label style={{ color: "black", fontWeight: "lighter" }}>
              {isEditMode ? (
                <b>Edit Answer :</b>
              ) : (
                <b>Add Answer :</b>
              )}
            </Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              rows={4}
              placeholder="Enter Answer.."
              name="answer"
              value={formik.values.answer}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              isInvalid={formik.touched.answer && !!formik.errors.answer}
            />
            <Form.Control.Feedback type="invalid">
              {formik.touched.answer && formik.errors.answer}
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="submit"
            style={{ backgroundColor: "#00ADEF" }}
            disabled={!formik.isValid || loading}
          >
            {isEditMode ? "Update FAQ " : "Add FAQ "}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddFaqQuestion;
