// DeleteGameSettingsModal.js
import { CircularProgress } from "@material-ui/core";
import React from "react";
import { Modal, Button } from "react-bootstrap";

const DeleteGameSettingsModal = ({
  show,
  handleClose,
  handleConfirmDelete,
  _id,
  pitchName,
  deleteLoading,
}) => {
  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this <strong>{pitchName}</strong> ?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose}>
          Cancel
        </Button>
        {deleteLoading ? (
          <Button
            variant="danger"
            className="d-flex justify-content-center align-items-center"
            style={{ width: "70px" }}
          >
            <CircularProgress
              style={{ width: "20px", height: "20px", color: "white" }}
            />
          </Button>
        ) : (
          <Button variant="danger" onClick={() => handleConfirmDelete(_id)}>
            Delete
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteGameSettingsModal;
