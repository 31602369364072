import React from "react";
import { Modal, Button } from "react-bootstrap";

const AdvertiseModal = ({
  show,
  handleClose,
  userId,
  handleAdvertise,
}) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>Confirm Advertise</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to Advertise this Game?</Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleClose}
          style={{ borderRadius: "10px" }}
        >
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={() => handleAdvertise(userId)}
          style={{ borderRadius: "10px" }}
        >
          Advertise
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AdvertiseModal;
