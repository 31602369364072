import React, { useEffect, useState } from "react";
import {
    MDBAccordion,
    MDBAccordionItem,
    MDBContainer,
    MDBTypography,
} from "mdb-react-ui-kit";
import { Button, Card, Col, Modal, Pagination, Row, Spinner } from "react-bootstrap";
import AddFaqQuestion from "./AddFaqQuestion";
import { useFormik } from "formik";
import * as yup from "yup";
import FaqDeleteModal from "./FaqDeleteModal";
import FaqData from "../../../../../services/faq.service";
import { enqueueSnackbar } from "notistack";

const schema = yup.object().shape({
    question: yup.string().min(5, "Enter que, at least 5 words.!").required("Please Add Question."),
    answer: yup.string().min(5, "Enter ans, at least 5 words.!").required("Please Add Answer."),
});

const Faq = () => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [editLoader, setEditLoader] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [count, setCount] = useState();
    const [page, setPage] = useState(1);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedFaq, setSelectedFaq] = useState(null);
    const [scroll, setScroll] = useState(true);
    const [totalPage, setTotalPage] = useState("");
    const pageSize = Math.ceil(totalPage / 12);



    useEffect(() => {
        if (scroll) {
            window.scrollTo(0, 0);
        }
    }, [scroll]);

    useEffect(() => {
        localStorage.setItem('faqData', JSON.stringify(data));
    }, [data]);

    const getFaqData = async () => {
        try {
            const response = await FaqData.getFqqQuestion(currentPage);
            setData(response.data["data"]);
            // enqueueSnackbar("Get FAQ successfully", {
            //     variant: "success", 
            //     anchorOrigin: {
            //       vertical: "bottom", 
            //       horizontal: "right"
            //     }
            //   })
            setLoading(false);
            setTotalPage(response.data.totalCount);
        } catch (error) {
            console.error("Error fetching data", error);
            setLoading(false);
        }
    };
    useEffect(() => {
        getFaqData();
    }, [currentPage]);

    useEffect(() => {
        window.addEventListener('resize', () => {
            const footer = document.querySelector('.sticky-footer');
            const footerHeight = footer.offsetHeight;
            document.body.style.paddingBottom = `${footerHeight}px`;
        });

        return () => {
            window.removeEventListener('resize', () => {
                const footer = document.querySelector('.sticky-footer');
                const footerHeight = footer.offsetHeight;
                document.body.style.paddingBottom = `${footerHeight}px`;
            });
        };
    }, []);


    useEffect(() => {
        getFaqData();
    }, [currentPage, page]);


    const handleShow = async (id) => {
        setSelectedFaq(id);
        setIsEditMode(true);
        setShowModal(true);
        if (id) {
            const response = await FaqData.GetFaqSingleId(id);
            const result = response.data.data
            formik.setFieldValue("id", result._id);
            formik.setFieldValue("answer", result.answer);
            formik.setFieldValue("question", result.question);
        } else {
            formik.setFieldValue("answer", "");
            formik.setFieldValue("question", "");
        }

    };



    const previousPage = () => {
        console.log("Going to previous page");
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const nextPage = (pageNumber) => {
        console.log("Going to next page");
        if (pageNumber <= pageSize) {
            setCurrentPage(pageNumber);
            setPage(pageNumber);
        }
    };


    const handleClose = () => {
        formik.resetForm(); 
        setShowModal(false); 
        setSelectedFaq(null);
        setIsEditMode(false);
    };



    const handleDelete = (_id) => {
        console.log("Deleting item:", _id);
        setShowDeleteModal(true);
        setSelectedRow(_id); 
    };

    const confirmDelete = async (id) => {
        try {
            const response = await FaqData.DeleteFaqDataRequest(id);
            console.log("Confirm Delete", response);
            setShowDeleteModal(false);
            getFaqData();
            enqueueSnackbar(response.data.message, {
                variant: "error", anchorOrigin: {
                    vertical: "bottom", horizontal: "right"
                }
            });
        } catch (e) {
            console.log(e.response.data.message);
            enqueueSnackbar("Error deleting city", {
                variant: "error", anchorOrigin: {
                    vertical: "bottom", horizontal: "right"
                }
            });
        }
    };

    const submit = async (values, action) => {
        console.log("HANDLE submit", values);
        setIsEditMode(false);
        if (!values.id) {
            try {
                const response = await FaqData.CreateFaqAddQuestion(values);
                console.log("AddFaqQuestionData", response);
                if (response.status === 200 || response.status === 201) {
                    enqueueSnackbar(response.data.message, {
                        variant: "success", autoHideDuration: 4000, anchorOrigin: {
                            vertical: "bottom", horizontal: "right"
                        }
                    })
                    formik.resetForm();
                    getFaqData();
                    handleClose();
                    action.resetForm();
                } else {
                    enqueueSnackbar("User already exists with this email", {
                        variant: "error", autoHideDuration: 5000, anchorOrigin: {
                            vertical: "bottom", horizontal: "right"
                        }
                    });
                }
            } catch (error) {
                enqueueSnackbar("User already exists with this email", {
                    variant: "error",
                });
                console.log("AddModeratorData", error);
            }

        } else {
            try {
                const response = await FaqData.UpdateFaqDataRequest(values.id, values);
                console.log("IMPresponse", response);
                action.resetForm();
                getFaqData();
                setShowModal(false);
                enqueueSnackbar(response.data.message, {
                    variant: "success", autoHideDuration: 4000, anchorOrigin: {
                        vertical: "bottom", horizontal: "right"
                    }
                })
            } catch (e) {
                enqueueSnackbar(e.response.data.message, {
                    variant: "error", autoHideDuration: 5000, anchorOrigin: {
                        vertical: "bottom", horizontal: "right"
                    }
                });
                console.log("e", e);
            }
        }
        handleClose();
        setIsEditMode(false);
    };


    const formik = useFormik({
        initialValues: {
            answer: selectedFaq ? selectedFaq.answer : "",
            question: selectedFaq ? selectedFaq.question : "", 
        },
        onSubmit: submit,
        validationSchema: schema,
    });

    return (
        <>
            <div className="page-header">
                <div>
                    <h3 className="main-content-title tx-24 mg-b-5">FAQ (Frequently Asked Questions)</h3>
                </div>
                <div className="d-flex">
                    <div className="justify-content-center">
                        <Button
                            variant="primary"
                            style={{ borderRadius: "5px" }}
                            type="button"
                            className="my-2 btn-icon-text"
                            onClick={() => {
                                setShowModal(true);
                                console.log("showModal", showModal);
                            }}
                        >
                            <i className="fe fe-plus me-2"></i>Create FAQ
                        </Button>
                    </div>
                </div>
            </div>
            {loading ? (
                <div className="loader-container">
                    <h3
                        style={{ position: "absolute", top: "50%", left: "50%", fontWeight: "bold" }}
                        className="loader"
                    >
                        Loading...
                    </h3>
                </div>
            ) : data.length === 0 ? (
                <div className="text-center my-4">
                    <p>No FAQ items available,if want added click "Create Add FAQ"</p>
                </div>
            ) : (
                <>
                    <Row className=" row-sm ">
                        <Col md={12} lg={12} xl={12}>
                            {data && data?.map((item, index) => (
                              <div style={{ marginBottom: "20px", width: "100%" }} key={index}>
                              <MDBAccordion alwaysOpen initialActive={index} style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                  <MDBAccordionItem style={{ whiteSpace: "pre-wrap", width: "100%" }} collapseId={1} headerTitle={
                                      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%"}} >
                                          <span style={{ whiteSpace: 'pre-wrap' }}>{item.question}</span>
                                          <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                              <Button style={{ marginLeft: "5px", borderRadius: "15px" }} onClick={() => handleShow(item._id)} className="btn btn-primary btn-sm">
                                                  <i className="fa fa-pencil"></i>
                                              </Button>
                                              <Button variant="danger" style={{ marginLeft: "5px", borderRadius: "15px" }} onClick={() => handleDelete(item._id)} className="btn btn-danger btn-sm">
                                                  <i className="fa fa-trash" />
                                              </Button>
                                          </div>
                                      </div>
                                  }>
                                      {item.answer}
                                  </MDBAccordionItem>
                              </MDBAccordion>
                          </div>
                          

                            ))}

                            <Pagination
                                className="pagination-circle mb-0 ms-lg-3"
                                style={{ justifyContent: "center", position: "relative" }}
                            >
                                <Pagination.Item
                                    onClick={() => previousPage()} 
                                    disabled={currentPage === 1} 
                                >
                                    <i className="fas fa-angle-double-left"></i>
                                </Pagination.Item>

                                {Array.from({ length: Math.ceil(totalPage / 12) }).map(
                                    (_, index) => {
                                        const pageNumber = index + 1;
                                        return (
                                            <Pagination.Item
                                                onClick={() => nextPage(pageNumber)}
                                                key={pageNumber}
                                                active={pageNumber === currentPage}
                                            >
                                                {pageNumber}
                                            </Pagination.Item>
                                        );
                                    }
                                )}

                                <Pagination.Item
                                    onClick={() => nextPage(currentPage + 1)} 
                                    disabled={currentPage === pageSize} 
                                >
                                    <i className="fas fa-angle-double-right"></i>
                                </Pagination.Item>
                            </Pagination>

                        </Col>
                    </Row>




                </>
            )}
            <AddFaqQuestion
                showModal={showModal}
                handleSave={formik.handleSubmit}
                handleClose={handleClose}
                selectedFaq={selectedFaq}
                formik={formik}
                isEditMode={isEditMode}
                loading={editLoader}
            />
            <FaqDeleteModal
                showDeleteModal={showDeleteModal}
                setShowDeleteModal={setShowDeleteModal}
                handleClose={() => setShowDeleteModal(false)}
                confirmDelete={confirmDelete}
                _id={selectedRow}
            />
        </>
    );
};

export default Faq;
