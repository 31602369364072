import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
// import "bootstrap/dist/css/bootstrap.min.css";

import { SnackbarProvider } from "notistack";
import store from "./store";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <SnackbarProvider maxSnack={1} preventDuplicate autoHideDuration={1500}>
      <Provider store={store}>
        <App />
      </Provider>
    </SnackbarProvider>
  </>
);
reportWebVitals();
