import moment from "moment";
import serverCall from "../serverCall";


//CreateFacility
const CreateFacility = async (data) => {
    try {
        const response = await serverCall.post(`/facility/admin/create`, data);
        console.log("Create FacilityApi response", response);
        return response;
    } catch (error) {
        console.log("Create FacilityApi error", error);
    }
};

//GetFacilitySingleId
const GetFacilitySingleId = async (id) => {
    try {
        const response = await serverCall.get(`/facility/admin/get/${id}`);
        console.log("Facility SingleId response", response);
        return response;
    } catch (error) {
        console.log("FacilitySingleId Api error", error);
    }
};

//UpdateFacilityDataRequest
const UpdateFacilityDataRequest = async (id, body) => {
    try {
        const response = await serverCall.put(`/facility/admin/update/${id}`, body);
        console.log("UpdateFaqDataRequest_response", response);
        return response;
    } catch (error) {
        console.log("GetFaqSingleId Api error", error);
    }
};

//DeleteFacilityDataRequest
const DeleteFacilityDataRequest = async (id) => {
    try {
        const response = await serverCall.delete(`/facility/admin/delete/${id}`);
        console.log("DeleteCityData response", response);
        return response;
    } catch (error) {
        console.log("DeleteCityData Api error", error);
    }
};

//GetFacilityGameSingleId
const GetFacilityGameSingleId = async (id, formatedDate) => {

    try {
        const response = await serverCall.get(`/facility/admin/games/${id}/${formatedDate}`);
        console.log("Facility SingleId response", response);
         console.log("hrsd formatedDate", formatedDate);
        return response;
    } catch (error) {
        console.log("FacilitySingleId Api error", error);
    }
};




const FacilityData = {
    CreateFacility,
    GetFacilitySingleId,
    UpdateFacilityDataRequest,
    DeleteFacilityDataRequest,
    GetFacilityGameSingleId
};

export default FacilityData;
