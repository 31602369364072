import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import {
  Formik,
  ErrorMessage,
  Form as FormikForm,
  Field,
  FieldArray,
} from "formik";
import * as Yup from "yup";
import moment from "moment";
import UserListData from "../../../../../services/userList.service";
import { useFormik } from "formik";
import GameSettingsData from "../../../../../services/gameSettings.service";

const GameDetailEditModal = ({
  show,
  handleClose,
  handleSave,
  isEdit,
  gameDetail,
  gameId,
  selectedDateRange,
  getGameSettingsData,
}) => {
  console.log("gameDetail", gameDetail);
  const [selectedRange, setSelectedRange] = useState(selectedDateRange || null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [search, setSearch] = useState("");
  const [selectedHost, setSelectedHost] = useState("");
  const [selectedGameType, setSelectedGameType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [gameSettings, setGameSettings] = useState([]);

  const getMainUserListData = async () => {
    try {
      setLoading(true);
      const response = await UserListData.getHostListData(search);
      console.log("Facility GetAll Page", response);
      setData(response.data["data"]);

      setLoading(false);
      setLoadingData(false);
    } catch (error) {
      console.log("getMainCategory errrrrr", error);
      setLoading(false);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    setSelectedHost(gameDetail.hostId);
  }, [gameDetail]);

  useEffect(() => {
    getMainUserListData();
  }, [search]);

  const handleHostChange = (event, setFieldValue) => {
    const selectedValue = event.target.value;
    setSelectedHost(selectedValue);
    setFieldValue("hostedBy", selectedValue);
    getMainUserListData();
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("Form_submitted_with_values", values.hostedBy);

    await GameSettingsData.UpdateGameSettingsDetailsOptions(
      gameId,
      values.hostedBy
    );
    setSubmitting(false);
    handleClose();
    setShowModal(false);
    getGameSettingsData();
  };

  const handleGameTypeChange = async (event, setFieldValue) => {
    console.log("hi", gameDetail);

    const formatStartTime = (time) => {
      return moment.utc(gameDetail.startTime).format("HH:mm");
    };
    const formatEndTime = (time) => {
      return moment.utc(gameDetail.endTime).format("HH:mm");
    };

    const startTimeFormatted = formatStartTime(new Date(gameDetail.startTime));
    const endTimeFormatted = formatEndTime(new Date(gameDetail.endTime));

    console.log("TIMEST", startTimeFormatted, endTimeFormatted);

    const json = {
      pitchId: gameDetail.pitchId,
      pitchTypeId: gameDetail.pitchtypeId,
      facilityId: gameDetail.facilityId,
      date: gameDetail.gameDate,
      startTime: startTimeFormatted,
      endTime: endTimeFormatted,
    };
    console.log("json", json);

    const response = await GameSettingsData?.GetGameSettingsDetailsOptions(
      json
    );
    setGameSettings(response?.data["data"]);

    // const { value } = event.target;
    // setSelectedGameType(value);
    // setFieldValue("name", value);
  };

  const validationSchema = Yup.object().shape({
    hostedBy: Yup.string().required("Host Name is required"),
    // firstName: Yup.string().required("Host Name is required"),
    // name: Yup.string().required("Game Setting is required"),
  });

  return (
    <Modal
      size="md"
      show={show}
      onHide={handleClose}
      centered
      backdrop="static"
    >
      <Modal.Header style={{ marginBottom: "0px" }} closeButton>
        <Modal.Title style={{ fontSize: "20px" }}>
          {isEdit ? "Update Game Settings" : "Create Game Settings"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          initialValues={{ hostedBy: "" }}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <FormikForm style={{ fontWeight: "500" }}>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="hostedBy">
                  <Form.Label style={{ color: "black" }}>
                    Host Name
                  </Form.Label>
                  <Field
                    as="select"
                    name="hostedBy"
                    className="form-select"
                    // onChange={(event) => {
                    //   setSelectedHost(event.target.value);
                    //   setFieldValue("hostedBy", event.target.value);
                    // }}
                    onChange={(event) => handleHostChange(event, setFieldValue)}
                    value={selectedHost}
                    style={{ textAlign: "center" }}
                  >
                    <option value="">Select Host Name</option>
                    {data.map((host) => (
                      <option key={host._id} value={host._id}>
                        {host.firstName} {host.lastName}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="hostedBy"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="name">
                  <Form.Label style={{ color: "black" }}>
                    Game-Settings
                  </Form.Label>
                  <Field
                    as="select"
                    name="name"
                    className="form-select"
                    onChange={(event) =>
                      handleGameTypeChange(event, setFieldValue)
                    }
                    onClick={(event) =>
                      handleGameTypeChange(event, setFieldValue)
                    }
                    value={selectedGameType}
                    style={{ textAlign: "center" }}
                  >
                    <option value="">Select Game Setting</option>
                    {gameSettings.map((setting) => (
                      <option key={setting.id} value={setting.name}>
                        {setting.name}
                      </option>
                    ))}
                  </Field>

                  <ErrorMessage
                    name="name"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
              </Row>

              <Row style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                  style={{ width: "40%", borderRadius: "15px" }}
                >
                  Submit
                </Button>
              </Row>
            </FormikForm>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default GameDetailEditModal;
