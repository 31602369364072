import serverCall from "../serverCall";

// GetAllGameSettings
const GetGetCreateGameSettings = async (name) => {
  try {
    const response = await serverCall.post(`/game/admin/create-game`, name);
    console.log("GetGetCreateGameSettings response", response);
    return response;
  } catch (error) {
    console.log("GetCommunityData error", error);
  }
};

//CreateGameSettings
const CreateGameSettings = async (name) => {
  try {
    const response = await serverCall.post(
      `/game-setting/get-settings/for-create-game`,
      name
    );
    console.log("CreateCommunityData response", response);
    return response;
  } catch (error) {
    console.log("CommunityDataSingleId Api error", error);
  }
};

// //GetWalletList
// const GetWalletList = async (id) => {
//     try {
//         const response = await serverCall.get(`/game/admin/weekly-revenue?facilityId=${id}`);
//         console.log("GetWalletList response", response);
//         return response;
//     } catch (error) {
//         console.log("GetWalletList Api error", error);
//     }
// };

// //DeleteGameSettings
// const DeleteGameSettings = async (id) => {
//     try {
//         const response = await serverCall.delete(`/game-setting/${id}`);
//         console.log("DeleteCommunityData response", response);
//         return response;
//     } catch (error) {
//         console.log("DeleteCommunityData Api error", error);
//     }
// };

// //UpdateGameSettings
// const UpdateGameSettings = async (id,name) => {
//     try {
//         const response = await serverCall.put(`/game-setting/${id}`,name);
//         console.log("UpdateCommunityData response", response);
//         return response;
//     } catch (error) {
//         console.log("UpdateCommunityData Api error", error);
//     }
// };

//GameIDForFacility
const GameIDForFacility = async (id) => {
  try {
    const response = await serverCall.get(`/game/admin/getone/${id}`);
    return response;
  } catch (error) {
    console.log("CityIDForFacility Api error", error);
  }
};

const updateMultiple = async (body) => {
  const response = await serverCall.post(`/game/admin/update-multiple`, body);
  return response;
};
const newUpdateMultiple = async (body) => {
  const response = await serverCall.post(`/game/admin/create-repeated`, body);
  return response;
};

const bookFullPitch = async (id, body) => {
  const response = await serverCall.post(`/game/admin/update/${id}`, body);
  return response;
};

const availableRepeated = async (body) => {
  const response = await serverCall.post(
    `/game/admin/available-repeated`,
    body
  );
  return response;
};

const GameData = {
  GetGetCreateGameSettings,
  // CreateGameSettings,
  // DeleteGameSettings,
  // UpdateGameSettings,
  // GameSettingsIDForFacility,
  GameIDForFacility,
  CreateGameSettings,
  updateMultiple,
  availableRepeated,
  newUpdateMultiple,
  bookFullPitch,
};

export default GameData;
